import React, { useState } from 'react'
import './styles.scss'
import logo from '../../assets/logo_new.png'
import logoWithoutText from '../../assets/logo_new_without_text.png'
import appName from '../../assets/decisionplus-text.svg'
import { Link } from 'react-router-dom'
import { routes, meetingMultiViewSubRoutes, changesReportSubRoutes } from '../../utils/routes'
import ActiveLink from '../ActiveLink'
import {
  areCredentialsAvailable,
  clearLocalStorage,
  getIsSupportSessionEnabled,
  hasRole,
  getCompanyConfigSettings,
  getHasSetupWizardAction,
  logout,
} from '../../utils/auth.js'
import useTestUserLoginData from '../../api/query/useTestUserLoginData.js'
import { useTranslation } from 'react-i18next'
import NavigationDropdownMenu from './navigationDropdown'
import { getNotificationsCount } from '../../api/services/notifications.services'
import UserConfigurationDropdown from './userConfigurationDropdown'
import NavigationMEI from '../NavigationMEI'
import NavigationMEITeam from '../NavigationMEITeam'
import { getConsolidatedConfigSettings } from '../../utils/auth.js'
import MeiHelpModal from '../../pages/Home/components/AdminDashboard/components/TeamDoing/component/MeiHelpModal'
import TutorialManagementModal from '../TutorialManagementModal'
import { getShowTlMei } from '../../utils/auth.js'
import StreamAudioRecorder from '../StreamAudioRecorder'
import { useMeetingRecording } from '../../utils/meetingRecordingContext/meetingRecordingContext'
import { useLocation } from 'react-router-dom'
import ChangesReport from '../ChangesReport'
import ChangesReportModal from '../ChangesReportModal'
import { useKanbanVoice } from '../../pages/kanban/kanbanContext/KanbanContext.jsx'
import StreamAudioRecorderKanban from '../StreamAudioRecorderKanban/index.jsx'
import { getFirstLetters } from '../../utils/name'
import UserSettingModal from '../UserSettingModal/index.jsx'

const buildMutatedText = (
  text,
  startIndex,
  endIndex,
  multiHighlightStartIndex,
  multiHighlightEndIndex,
) => {
  if (multiHighlightStartIndex.length > 0 && multiHighlightEndIndex.length > 0) {
    const highlights = multiHighlightStartIndex.map((start, index) => {
      const end = multiHighlightEndIndex[index]
      return (
        <React.Fragment key={index}>
          {text.substring(index === 0 ? 0 : multiHighlightEndIndex[index - 1] + 1, start)}
          <span style={{ color: 'red', fontWeight: 'bold' }}>{text.substring(start, end + 1)}</span>
        </React.Fragment>
      )
    })

    return (
      <>
        {highlights}
        {text.substring(multiHighlightEndIndex[multiHighlightEndIndex.length - 1] + 1, 50)}
        {text.length > 50 && '...'}
      </>
    )
  }
  return (
    <>
      {text.substring(0, startIndex)}
      <span style={{ color: 'red', fontWeight: 'bold' }}>
        {text.substring(startIndex, endIndex + 1)}
      </span>
      {text.substring(endIndex + 1, 50)}
      {text.length > 50 && '...'}
    </>
  )
}

const Navigation = ({
  loginProtected = true,
  children,
  onNavbarStateChange,
  isNavbarCollapsed,
  fromSetUpAgent = false,
}) => {
  const { state } = useMeetingRecording()
  const { state: kanbanVoiceState } = useKanbanVoice()
  const [isMenuClosed, setIsMenuClosed] = useState(isNavbarCollapsed || fromSetUpAgent)
  const fullName = localStorage.getItem('tfmnm') || ''
  const loggedFirstName = fullName.split(' ')[0]
  const loggedLastName = fullName.split(' ').slice(1).join(' ') || ''
  const loggedInName = localStorage.getItem('tfmwl')
    ? JSON.parse(localStorage.getItem('tfmwl'))
    : { first_name: loggedFirstName, last_name: loggedLastName }
  const enableIndividualUserMei =
    getConsolidatedConfigSettings('enable_individual_user_mei') === true
  const enableTeamMeiForLeader =
    getConsolidatedConfigSettings('enable_team_mei_for_leader') === true
  const [isHelpModalOpen, setIsHelpModalOpen] = React.useState(false)
  const [isTutorialManagementModalOpen, setIsTutorialManagementModalOpen] = React.useState(false)
  const [isNotficationModalOpen, setIsNotficationModalOpen] = React.useState(false)
  const [isUserSettingsModalOpen, setIsUserSettingsModalOpen] = React.useState(false)

  const [reports, setReports] = React.useState([])

  const enableReviewObjectiveMapView =
    getConsolidatedConfigSettings('enable_review_objective_map_view') === true
  const enableL2ReviewObjectiveMapView =
    getConsolidatedConfigSettings('enable_review_objective_map_l2_view') === true

  let icShowTlMei = getShowTlMei() === 'true'

  const productSettings = getCompanyConfigSettings('productSettings')
  const enableSummaryOfObjectives =
    getConsolidatedConfigSettings('enable_summary_of_objectives') === true

  const enableSetupAgent =
    getConsolidatedConfigSettings('enable_setup_agent') === true && getHasSetupWizardAction()

  const enbaleBrainStorming = getConsolidatedConfigSettings('enable_brainstorming') === true

  const isPA = localStorage.getItem('tfci') == 44 && !hasRole('enterprise')
  // Karla's enterprise account
  const isPAEnterprise =
    localStorage.getItem('tfci') == 44 && hasRole('enterprise') && hasRole('team')

  React.useEffect(() => {
    setIsMenuClosed(state.hasSummarizationStarted)
  }, [state.hasSummarizationStarted])
  const location = useLocation()
  const handleRouteChange = React.useCallback(() => {
    if (
      location.pathname === '/Kanban' ||
      location.pathname === '/AIConsultantSetupStep1' ||
      location.pathname === '/AIConsultantSetupStep2' ||
      location.pathname == 'SetupIntro' ||
      location.pathname == '/MeetingMultiView/KpiReportByLeader' ||
      location.pathname == '/TeamMeetingView/KpiReportByLeader' ||
      location.pathname == '/AIConsultantSetupL2' ||
      location.pathname == '/AIConsultantSetupL3' ||
      location.pathname == '/BrainStorming'
    ) {
      setIsMenuClosed(true)
    }
  }, [location.pathname, setIsMenuClosed])

  React.useEffect(() => {
    // Check the route when the component mounts or when the location changes
    handleRouteChange()

    // No need for popstate event listener as useLocation will trigger a re-render on route change
  }, [handleRouteChange])

  React.useEffect(() => {
    onNavbarStateChange(isMenuClosed)
  }, [isMenuClosed])

  React.useEffect(() => {
    //All of this use effect is being used to efficiently fetch the notification count only once per minute and not per page reload,
    //when notifications are cleared, the event listener is triggered and the notification count is gotten from a count stored in local storage
    //when the notification page reloads on clear a refetches notifications from backend
    let lastFetchNotificationCount = localStorage.getItem('lastFetchNotificationCountTS')

    //refecth notification count api if it has been more than a minute since last fetch or if it has never been fetched on login
    if (
      (lastFetchNotificationCount && Date.now() - lastFetchNotificationCount > 60000) ||
      lastFetchNotificationCount === null ||
      lastFetchNotificationCount === undefined
    ) {
      loadNotificationsCount()
    } else {
      let notificationCount = localStorage.getItem('notificationCount')
      if (notificationCount) {
        setNotificationsCountLet(notificationCount)
      }
    }

    const handleClearNotificationList = () => {
      let notificationCount = localStorage.getItem('notificationCount')
      setNotificationsCountLet(notificationCount)
    }

    window.addEventListener('clearNotifications', handleClearNotificationList)

    // Cleanup listener
    return () => {
      window.removeEventListener('clearNotifications', handleClearNotificationList)
    }
  }, [])

  const [notificationsCountLet, setNotificationsCountLet] = useState(0)

  let credsAvailable = areCredentialsAvailable()
  const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)
  const [industry, setIndustry] = useState(
    localStorage.getItem('iType') != null ? localStorage.getItem('iType') : 'insurance',
  )
  if (loginProtected && !credsAvailable) {
    // alert("We apologize, an error has occurred. Please let your TransforML partner know of error code 0x3A. Please log in again.")
    localStorage.setItem('logoutReasonJan_3A', '0x3A')

    window.location.href = '/'
  }

  const isDelegateTl = parseInt(localStorage.getItem('dType')) === 5 ? true : false
  const currentUser = localStorage.getItem('tfei') || ''
  const canUseMeetingSummarization =
    getConsolidatedConfigSettings('can_use_meeting_summarization') === true
  const enableKanbanVoice = getConsolidatedConfigSettings('enable_kanban_voice') === true

  // Show L2 MEI for Brian, Isela, and Sebas
  const showL2BetaMei = [1158, 1041, 1026].some((elem) => parseInt(currentUser) === elem)
  // const showReportsAndNotification = false
  // [1158, 1631, 1629].some(
  //   (elem) => parseInt(currentUser) === elem
  // )

  const enablekpidashboards = getConsolidatedConfigSettings('enable_kpi_dashboards') === true

  // TODO(ali): After the initial MVP, use a more robust method for this.
  // This is not dangerous right now, because the backend still controls whether the endpoint
  // can be access, but this kind of rendering logic should be moved away from the frontend
  // completely.
  const showReset = localStorage.getItem('dMd') === 'true'

  const { data: testEmployeeData, isLoading: isTestEmployeeLoading } =
    useTestUserLoginData(showReset)
  const { t } = useTranslation(['Common'])

  if (showReset && isTestEmployeeLoading) {
    return (
      <div className="navigation">
        <div className="nav-div">
          <div
            className={
              'logo-container ' + (isMenuClosed ? 'logo-container-closed' : 'logo-container-open')
            }
          >
            <Link to={'/'}>
              <img className="logo" src={logo} alt="TransforML Logo" />
            </Link>
          </div>
        </div>
      </div>
    )
  }
  const showNavModal = () => {
    document.querySelector('.nav-modal').classList.remove('hidden')
  }

  function openTutorialSettingsModal() {
    setIsTutorialManagementModalOpen(true)
  }

  function closetutorialSettings() {
    setIsTutorialManagementModalOpen(false)
  }

  function openUserSettingsModal() {
    setIsUserSettingsModalOpen(true)
  }

  function closeUserSettings() {
    setIsUserSettingsModalOpen(false)
  }

  const setLoggedinUser = (event) => {
    //TBD Swetha : Remove local-storage when not required
    // localStorage.removeItem('tlw-data')
    // localStorage.removeItem('tlw-step')
    localStorage.removeItem('uw-data')
    localStorage.removeItem('uw-step')
    const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)
    window.location.href = `${BASE_URL}/f/set_test_user2/${event.target.value}`
  }

  const setDBPreset = (event) => {
    //TBD Swetha : Remove local-storage when not required
    // localStorage.removeItem('tlw-data')
    // localStorage.removeItem('tlw-step')
    localStorage.removeItem('uw-data')
    localStorage.removeItem('uw-step')
    window.location.href = `${BASE_URL}/reset_from_dd?rt=${event.target.value}&iType=${industry}`
  }

  const setIndustryFilter = (event) => {
    localStorage.setItem('iType', event.target.value)
    setIndustry(event.target.value)
    window.location.href = `${BASE_URL}/f/set_test_user2/ahmad@transforml.co`
  }

  async function loadNotificationsCount() {
    let result = await getNotificationsCount()

    if (result && result.count) {
      setNotificationsCountLet(result.count)
      localStorage.setItem('lastFetchNotificationCountTS', Date.now())
      localStorage.setItem('notificationCount', result.count)
    }
  }

  const handleGoBackToMyProfile = () => {
    const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)
    window.location.href = `${BASE_URL}/f/switch_user_profile`
  }

  const getRespectiveAudioRecorder = () => {
    if (location.pathname.endsWith('/BrainStorming')) {
      console.log('0')
      return null
    }
    // when meeting summary is started, show ONLY meeting summary audio recorder
    if (canUseMeetingSummarization && state.hasSummarizationStarted) {
      console.log('1')
      return <StreamAudioRecorder />
      // when kanban voice is started, show ONLY kanban voice audio recorder
    } else if (enableKanbanVoice && kanbanVoiceState.hasKanbanVoiceStarted) {
      console.log('2')
      return <StreamAudioRecorderKanban />
      // when neither meeting summary nor kanban voice is started, show the respective audio recorder
    } else if (!(state.hasSummarizationStarted || kanbanVoiceState.hasKanbanVoiceStarted)) {
      console.log('3')
      console.log(location.pathname.endsWith('/Kanban'))
      console.log(enableKanbanVoice)
      if (location.pathname.endsWith('/Kanban') && enableKanbanVoice) {
        console.log('4')
        return <StreamAudioRecorderKanban />
      } else if (canUseMeetingSummarization) {
        console.log('5')
        return <StreamAudioRecorder />
      }
    }

    return null
  }

  return (
    <>
      {isHelpModalOpen && (
        <MeiHelpModal isModalOpen={isHelpModalOpen} closeModal={() => setIsHelpModalOpen(false)} />
      )}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="navigation-top">
          <div
            className={`logo-container ${
              isMenuClosed || fromSetUpAgent ? 'logo-container-closed' : 'logo-container-open'
            }`}
          >
            {hasRole('ic') ? (
              productSettings ? (
                <Link to={'/SummaryOfObjectives'}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '0.5rem',
                      height: '100%',
                      paddingLeft: '0.5rem',
                    }}
                  >
                    {(isMenuClosed || fromSetUpAgent) && (
                      <img
                        className="logo-new-folded"
                        src={logoWithoutText}
                        alt="TransforML Logo"
                      />
                    )}
                    {!isMenuClosed && !fromSetUpAgent && (
                      <img className="logo-new" src={logo} alt="TransforML Logo" />
                    )}
                  </div>
                </Link>
              ) : (
                <Link to={'/kanban'}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '0.5rem',
                      height: '100%',
                      paddingLeft: '0.5rem',
                    }}
                  >
                    {(isMenuClosed || fromSetUpAgent) && (
                      <img
                        className="logo-new-folded"
                        src={logoWithoutText}
                        alt="TransforML Logo"
                      />
                    )}
                    {!isMenuClosed && !fromSetUpAgent && (
                      <img className="logo-new" src={logo} alt="TransforML Logo" />
                    )}
                  </div>
                </Link>
              )
            ) : hasRole('area') || hasRole('cxo') || hasRole('team') || hasRole('ceo') ? (
              <Link to={'/MeetingMultiView'}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '0.5rem',
                    height: '100%',
                    paddingLeft: '0.5rem',
                  }}
                  onClick={(event) => {
                    if (fromSetUpAgent) {
                      event.preventDefault()
                    }
                  }}
                >
                  {(isMenuClosed || fromSetUpAgent) && (
                    <img className="logo-new-folded" src={logoWithoutText} alt="TransforML Logo" />
                  )}
                  {!isMenuClosed && !fromSetUpAgent && (
                    <img className="logo-new" src={logo} alt="TransforML Logo" />
                  )}
                </div>
              </Link>
            ) : hasRole('enterprise') ? (
              <Link to={'/Governance'}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '0.5rem',
                    height: '100%',
                    paddingLeft: '0.5rem',
                  }}
                >
                  {(isMenuClosed || fromSetUpAgent) && (
                    <img className="logo-new-folded" src={logoWithoutText} alt="TransforML Logo" />
                  )}
                  {!isMenuClosed && !fromSetUpAgent && (
                    <img className="logo-new" src={logo} alt="TransforML Logo" />
                  )}
                </div>
              </Link>
            ) : null}

            {!isMenuClosed && !fromSetUpAgent && (
              <span
                class="navigation-toggle material-symbols-outlined"
                //style={{ paddingTop: '0.5rem' }}
                onClick={() => {
                  setIsMenuClosed(true)
                }}
              >
                chevron_left
              </span>
            )}
            {isMenuClosed && !fromSetUpAgent && (
              <span
                class="navigation-toggle material-symbols-outlined"
                onClick={() => {
                  setIsMenuClosed(false)
                }}
              >
                chevron_right
              </span>
            )}
          </div>

          <div
            className={`top-menu-right-side ${
              isMenuClosed ? 'top-menu-right-side-closed' : 'top-menu-right-side-open'
            }`}
            style={{
              width:
                location.pathname.includes('AIConsultantSetupStep1') ||
                location.pathname.includes('AIConsultantSetupStep2') ||
                location.pathname.includes('BrainStorming')
                  ? '94%'
                  : `calc(100% - ${isMenuClosed ? '75px' : '228px'})`,
            }}
          >
            {loginProtected && (
              <div className="nav-link-container-top">
                {(hasRole('team') || hasRole('area') || hasRole('ic') || hasRole('cxo')) &&
                  !fromSetUpAgent && (
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                      <NavigationDropdownMenu />
                      {getRespectiveAudioRecorder()}
                    </div>
                  )}
              </div>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '1rem',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {loginProtected && !fromSetUpAgent && (
                <>
                  {(enableIndividualUserMei || icShowTlMei) && <NavigationMEI />}

                  {enableTeamMeiForLeader && <NavigationMEITeam />}

                  {(enableIndividualUserMei || enableTeamMeiForLeader || icShowTlMei) && (
                    <span
                      style={{
                        color: '#4472c4',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      onClick={() => {
                        setIsHelpModalOpen(true)
                      }}
                    >
                      {t('navigation.whatIsMEI')}
                    </span>
                  )}
                </>
              )}
              {loginProtected && !fromSetUpAgent && (
                <ActiveLink
                  to={`/${routes.notifications}`}
                  style={{ alignItems: 'center', paddingTop: '0.5rem' }}
                >
                  <span
                    className="material-symbols-outlined"
                    style={{ position: 'relative', display: 'inline-block' }}
                  >
                    notifications
                    {notificationsCountLet > 0 && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '0px',
                          right: '-3px',
                          backgroundColor: 'red',
                          color: 'white',
                          borderRadius: '50%',
                          padding: '1px 3px',
                          fontSize: '10px',
                          fontWeight: 'bold',
                          fontFamily: '"Open Sans", "Arial", "Helvetica", "system-ui", sans-serif',
                        }}
                      >
                        {notificationsCountLet}
                      </div>
                    )}
                  </span>
                </ActiveLink>
              )}
              {getIsSupportSessionEnabled() && (
                <div style={{ display: 'flex', flexDirection: 'column', fontSize: '14px' }}>
                  <span style={{ textAlign: 'right' }}>{t('navigation.youAreViewing')}:</span>
                  <div
                    style={{
                      color: '#0926D5',
                      textDecoration: 'underline',
                    }}
                  >
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        handleGoBackToMyProfile()
                      }}
                    >
                      {t('navigation.goBackToMyProfile')}
                    </span>
                  </div>
                </div>
              )}

              {loginProtected && (
                <span
                  className="tooltip circle-medium user-initials-circle"
                  style={{
                    backgroundColor: 'rgb(0, 82, 204)',
                  }}
                >
                  {getFirstLetters(loggedInName['first_name'], loggedInName['last_name'])}
                </span>
              )}
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <span style={{ alignItems: 'center' }}>
                  {loggedInName['first_name'] + ' ' + loggedInName['last_name']}
                </span>
                <UserConfigurationDropdown
                  loginProtected={loginProtected}
                  logout={logout}
                  tutorialSettings={openTutorialSettingsModal}
                  userSettings={openUserSettingsModal}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <div
            className={`navigation ${isMenuClosed || fromSetUpAgent ? 'navigation-closed' : 'navigation-open'}`}
          >
            {!fromSetUpAgent && loginProtected && (
              <div
                className={`nav-link-container ${isMenuClosed ? 'nav-link-container-closed' : ''}`}
              >
                {/* Deprecated link */}
                {/* {(hasRole('team') ||
                  hasRole('area') ||
                  hasRole('enterprise') ||
                  hasRole('cxo') ||
                  hasRole('ceo')) && (
                  <ActiveLink to={'/'} end>
                    <div className={`nav-link ${isMenuClosed ? 'nav-link-closed' : ''}`}>
                      <span class="material-symbols-outlined icon-size">team_dashboard</span>
                      {!isMenuClosed && <>{t('navigation.dashboard')}</>}
                    </div>
                  </ActiveLink>
                )} */}
                {(hasRole('area') ||
                  hasRole('team') ||
                  hasRole('ceo') ||
                  hasRole('cxo') ||
                  hasRole('cos')) && (
                  <ActiveLink to={`/${routes.objectives}`}>
                    <div className={`nav-link ${isMenuClosed ? 'nav-link-closed' : ''}`}>
                      <span class="material-symbols-outlined icon-size">target</span>
                      {!isMenuClosed && <>{t('navigation.objectives')}</>}
                    </div>
                  </ActiveLink>
                )}
                {/* {hasRole('team') && (
              <ActiveLink to={`/${routes.teamleader}`}>
                <div className="nav-link">Team Wizard</div>
              </ActiveLink>
            )} */}
                {/* Commenting out Provide Updates as Activity board is in place*/}
                {/* {hasRole('team') && (
              <ActiveLink to={`/${routes.update}`}>
                <div className="nav-link">Provide Updates</div>
              </ActiveLink>
            )} */}
                {(productSettings || enableSummaryOfObjectives) && (
                  <ActiveLink
                    to={
                      hasRole('team')
                        ? `/${routes.summaryOfObjectives}`
                        : `/${routes.summaryOfObjectives}`
                    }
                  >
                    <div className={`nav-link ${isMenuClosed ? 'nav-link-closed' : ''}`}>
                      <span class="material-symbols-outlined icon-size">lists</span>
                      {!isMenuClosed &&
                        (productSettings === true
                          ? t('navigation.initiativesReport')
                          : t('navigation.summaryOfObjectives'))}
                    </div>
                  </ActiveLink>
                )}
                {!isPA &&
                  (hasRole('team') ||
                    (hasRole('ic') && !productSettings) ||
                    hasRole('area') ||
                    hasRole('cxo') ||
                    hasRole('ceo') ||
                    hasRole('cos')) && (
                    <ActiveLink to={`/${routes.kanban}`}>
                      <div className={`nav-link ${isMenuClosed ? 'nav-link-closed' : ''}`}>
                        <span class="material-symbols-outlined icon-size">view_kanban</span>
                        {!isMenuClosed && <>{t('navigation.kanban')}</>}
                      </div>
                    </ActiveLink>
                  )}
                {(hasRole('area') ||
                  hasRole('cxo') ||
                  hasRole('ceo') ||
                  hasRole('cos') ||
                  (hasRole('team') && isDelegateTl)) && (
                  // <ActiveLink to={`/${routes.meetingview}`}>
                  //   <div className="nav-link">Decision Meeting</div>
                  // </ActiveLink>
                  <ActiveLink
                    to={`/${routes.meetingMultiView}`}
                    disableActive={location.pathname.endsWith(
                      `/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.overview}`,
                    )}
                  >
                    <div className={`nav-link ${isMenuClosed ? 'nav-link-closed' : ''}`}>
                      <span class="material-symbols-outlined icon-size">view_carousel</span>
                      {!isMenuClosed && <>{t('navigation.decisionMeeting')}</>}
                    </div>
                  </ActiveLink>
                )}
                {/* <ActiveLink to={`/${routes.leadership}`}>
            <div className="nav-link">Area Leader Wizard</div>
          </ActiveLink> */}
                {/* <ActiveLink to={`/${routes.setupwizard}`}>
            <div className="nav-link">Setup Wizard</div>
          </ActiveLink> */}
                {((hasRole('team') && !isDelegateTl) || (hasRole('ic') && !productSettings)) && (
                  <ActiveLink to={`/${routes.meetingMultiView}`}>
                    <div className={`nav-link ${isMenuClosed ? 'nav-link-closed' : ''}`}>
                      <span class="material-symbols-outlined icon-size">view_carousel</span>
                      {!isMenuClosed && <>{t('navigation.decisionMeeting')}</>}
                    </div>
                  </ActiveLink>
                )}
                {/* {hasRole('team') && (
              <ActiveLink to={`/${routes.update}`}>
                <div className="nav-link">Updates</div>
              </ActiveLink>
            )} */}
                {/* {(hasRole('team') || hasRole('area') || hasRole('enterprise')) && (
              <ActiveLink to={`/${routes.organizationstrategy}`} end>
                <div className="nav-link">{t('navigation.strategy')}</div>
              </ActiveLink>
            )} */}
                {!isPA && !isPAEnterprise && !(hasRole('ic') && productSettings) && (
                  <ActiveLink to={`/${routes.objectivemap}`} end>
                    <div className={`nav-link ${isMenuClosed ? 'nav-link-closed' : ''}`}>
                      <span
                        class="material-symbols-outlined icon-size"
                        style={{ transform: 'rotate(0.25turn)' }}
                      >
                        account_tree
                      </span>
                      {!isMenuClosed && <>{t('navigation.objectiveMap')}</>}
                    </div>
                  </ActiveLink>
                )}
                {!isPA && hasRole('cxo') && (
                  <>
                    {showL2BetaMei ? (
                      <ActiveLink to={`/${routes.cxomgmtscoreL2Beta}`} end>
                        <div className={`nav-link ${isMenuClosed ? 'nav-link-closed' : ''}`}>
                          <span class="material-symbols-outlined icon-size">
                            dashboard_customize
                          </span>
                          {!isMenuClosed && <>MEI</>}
                        </div>
                      </ActiveLink>
                    ) : (
                      <ActiveLink to={`/${routes.cxomgmtscore}`} end>
                        <div className={`nav-link ${isMenuClosed ? 'nav-link-closed' : ''}`}>
                          <span class="material-symbols-outlined icon-size">
                            dashboard_customize
                          </span>
                          {!isMenuClosed && <>MEI</>}
                        </div>
                      </ActiveLink>
                    )}
                  </>
                )}
                {/* { (hasRole('area') ||
                  hasRole('cxo')) &&
                  <ActiveLink to={`/${routes.agedItemsRanking}`} end>
                  <div className={`nav-link ${isMenuClosed ? 'nav-link-closed' : ''}`}>
                    <span
                      class="material-symbols-outlined icon-size"
                      style={{ transform: 'rotate(0.25turn)' }}
                    >
                      grouped_bar_chart
                    </span>
                    {!isMenuClosed && <>{t('navigation.agedItem')}</>}
                  </div>
                </ActiveLink>} */}
                {(hasRole('enterprise') || hasRole('admin')) && (
                  <ActiveLink to={`/${routes.governance}`}>
                    <div className={`nav-link ${isMenuClosed ? 'nav-link-closed' : ''}`}>
                      <span class="material-symbols-outlined icon-size">admin_panel_settings</span>
                      {!isMenuClosed && <>{t('navigation.governance')}</>}
                    </div>
                  </ActiveLink>
                )}
                {!isPA && !isPAEnterprise && !(hasRole('ic') && productSettings) && (
                  <ActiveLink to={`/${routes.myNotebook}`}>
                    <div className={`nav-link ${isMenuClosed ? 'nav-link-closed' : ''}`}>
                      <span class="material-symbols-outlined icon-size">menu_book</span>
                      {!isMenuClosed && <>{t('navigation.myNotebook')}</>}
                    </div>
                  </ActiveLink>
                )}
                {(enableReviewObjectiveMapView || enableL2ReviewObjectiveMapView) && (
                  <ActiveLink to={`/${routes.reviewObjectiveMap}`}>
                    <div className={`nav-link ${isMenuClosed ? 'nav-link-closed' : ''}`}>
                      <span class="material-symbols-outlined icon-size">search_insights</span>
                      {!isMenuClosed && <>Big Room Planning</>}
                    </div>
                  </ActiveLink>
                )}
                {enableSetupAgent && (
                  <ActiveLink to={`/${routes.setupIntroScreen}`}>
                    <div className={`nav-link ${isMenuClosed ? 'nav-link-closed' : ''}`}>
                      <span class="material-symbols-outlined icon-size">app_registration</span>
                      {!isMenuClosed && <>{t('AI Consultant')}</>}
                    </div>
                  </ActiveLink>
                )}
                {(hasRole('team') ||
                  hasRole('area') ||
                  hasRole('cxo') ||
                  hasRole('cos') ||
                  hasRole('ceo')) &&
                  enablekpidashboards && (
                    <ActiveLink to={`/${routes.changesReport}/${changesReportSubRoutes.kpisV2}`}>
                      <div className={`nav-link ${isMenuClosed ? 'nav-link-closed' : ''}`}>
                        <span class="material-symbols-outlined icon-size">table_chart</span>
                        {!isMenuClosed && <>{t('navigation.report')}</>}
                      </div>
                    </ActiveLink>
                  )}{' '}
                {enbaleBrainStorming && (
                  <ActiveLink to={`/${routes.brainstorm}`}>
                    <div className={`nav-link ${isMenuClosed ? 'nav-link-closed' : ''}`}>
                      <span class="material-symbols-outlined icon-size">psychology</span>
                      {!isMenuClosed && <>{t('navigation.brainstorming')}</>}
                    </div>
                  </ActiveLink>
                )}
                {/* {(showReset || currentUser.includes("@walmart.com") || currentUser.includes("@transforml.co")) && hasRole('area') && (
              <ActiveLink to={`/${routes.areaLeader}`} end>
                <div className="nav-link"> {t('navigation.areaLeaderWiz')}</div>
              </ActiveLink>
            )} */}
                {/* <a href="/reset_db">
            <div className="nav-link">Reset</div>
          </a>
          <a href="/reset_db_two_wizards">
            <div className="nav-link">Reset to completed</div>
          </a> */}
                {!isMenuClosed && (
                  <>
                    {showReset && (
                      <select
                        onChange={setIndustryFilter}
                        value={industry}
                        style={{ width: '10rem' }}
                      >
                        <option>Select Industry</option>
                        <option value="insurance">Insurance</option>
                        <option value="retail">Retail</option>
                        <option value="supplyChain">Supply Chain</option>
                        <option value="banking">Banking</option>
                      </select>
                    )}
                    {showReset && (
                      <select onChange={setDBPreset} style={{ width: '10rem' }}>
                        <option>Select Preset</option>
                        <option value="one">One Wiz</option>
                        <option value="two">Two Wiz</option>
                        <option value="three">Three Wiz</option>
                        <option value="four">Four Wiz</option>
                      </select>
                    )}
                  </>
                )}
              </div>
            )}
            {/* Upon selecting a user, redirect to a fake login handler in python 
        /f/set_test_user/<login_email>
        */}

            {loginProtected && showReset && !isMenuClosed && (
              <>
                &nbsp;
                <select onChange={setLoggedinUser} style={{ width: '10rem', marginLeft: '1rem' }}>
                  <option key={0}>Select User</option>

                  {!isTestEmployeeLoading &&
                    testEmployeeData.test_emps?.map((emp, index) => (
                      <option value={emp.email} key={index + 1}>
                        {emp.email} - {emp.roles_str}
                      </option>
                    ))}
                </select>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </>
            )}
            {/* {loginProtected &&
              !isMenuClosed &&
              (hasRole('cxo') || hasRole('area')) &&
              showReportsAndNotification && (
                <div style={{ marginTop: 'auto', marginBottom: '1rem' }}>
                  <ChangesReport
                    setIsNotficationModalOpen={setIsNotficationModalOpen}
                    setReports={setReports}
                    reports={reports}
                    buildMutatedText={buildMutatedText}
                  />
                </div>
              )} */}
          </div>

          <div
            className={
              isMenuClosed || fromSetUpAgent ? 'content-container-closed' : 'content-container-open'
            }
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {children}
          </div>
        </div>
      </div>
      {isTutorialManagementModalOpen && (
        <TutorialManagementModal
          isModalOpen={isTutorialManagementModalOpen}
          closeModal={closetutorialSettings}
        />
      )}
      {isUserSettingsModalOpen && (
        <UserSettingModal isModalOpen={isUserSettingsModalOpen} closeModal={closeUserSettings} />
      )}
      {isNotficationModalOpen && (
        <ChangesReportModal
          closeModal={() => {
            setIsNotficationModalOpen(false)
          }}
          reports={reports}
          buildMutatedText={buildMutatedText}
        />
      )}
    </>
  )
}

export default Navigation
