import React from 'react'
import { getHttpRequest } from '../../api/query/dynamicAPI'
import SelectSingleField from '../../components/CustomMultipleSelect/singleOption'
import { useTranslation } from 'react-i18next'
import ApplicationLayout from '../../components/ApplicationLayout'
import PieCharCustomizedLabel from '../../components/PieCharCustomizedLabel'
import InPageLoader from '../../components/InPageLoader'
import LineChartCustomizedLabel from '../../components/LineChartCustomizedLabel'
import { dayMonthFilter, dateFilter } from '../../utils/time'
import styled from 'styled-components'
import { getCompanyConfigSettings, getConsolidatedConfigSettings } from '../../utils/auth'
import { shortenName, truncateName } from '../../utils/general'
import CardModal from '../../components/CardModal'
import Toast from '../../common/toast'
import { hasRole } from '../../utils/auth'
import './style.css'
import { useLocation } from 'react-router-dom'
import Select from 'react-select'
import StackedBarCustomizedLabel from '../../components/StackedBarCustomizedLabel'
import KpiDropdownMenu from '../../components/KPI/KpiDropdownMenu'
import AddEditKPIModal from '../../components/KPI/AddEditKPIModal'
import KPIUpdatesModal from '../../components/KPI/KPIUpdatesModal'
import UpdateKPIModal from '../../components/KPI/UpdateKPIModal'

const NameBox = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px;
  border-radius: 100%;
  height: 14px;
  width: 14px;
  color: #0926d5;
  border: 2px solid #e4e7fd;
  font-weight: bold;
  font-size: 16px;
`

const ObjectiveStatement = styled.div`
  font-size: 16px;
  width: -moz-fit-content;
  width: fit-content;
  word-break: break-word;
`

const RagList = ({ reportStats }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        columnGap: '0.5rem',
        margin: 'auto 0',
        fontSize: '15px',
        marginLeft: 'auto',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className={'rag-letter-box red-rag-box'} style={{ height: '1.2rem', width: '1.2rem' }}>
          <span class="icon-size">R</span>
        </div>
        <div style={{ marginLeft: '0.3rem', display: 'flex', flexDirection: 'row', width: '60px' }}>
          <span>
            {!reportStats?.sumKpiReportStats?.sum || reportStats?.sumKpiReportStats?.sum <= 0
              ? '0'
              : (
                  (reportStats.sumKpiReportStats.red / reportStats.sumKpiReportStats.sum) *
                  100
                ).toFixed(0)}
            %
          </span>
          <span style={{ marginLeft: '0.1rem' }}>({reportStats?.sumKpiReportStats?.red ?? 0})</span>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          className={'rag-letter-box amber-rag-box'}
          style={{ height: '1.2rem', width: '1.2rem' }}
        >
          <span class="icon-size">A</span>
        </div>
        <div style={{ marginLeft: '0.3rem', display: 'flex', flexDirection: 'row', width: '60px' }}>
          <span>
            {!reportStats?.sumKpiReportStats?.sum || reportStats?.sumKpiReportStats?.sum <= 0
              ? '0'
              : (
                  (reportStats.sumKpiReportStats.yellow / reportStats.sumKpiReportStats.sum) *
                  100
                ).toFixed(0)}
            %
          </span>
          <span style={{ marginLeft: '0.1rem' }}>
            ({reportStats?.sumKpiReportStats?.yellow ?? 0})
          </span>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          className={'rag-letter-box green-rag-box'}
          style={{ height: '1.2rem', width: '1.2rem' }}
        >
          <span class="icon-size">G</span>
        </div>
        <div style={{ marginLeft: '0.3rem', display: 'flex', flexDirection: 'row', width: '60px' }}>
          <span>
            {!reportStats?.sumKpiReportStats?.sum || reportStats?.sumKpiReportStats?.sum <= 0
              ? '0'
              : (
                  (reportStats.sumKpiReportStats.green / reportStats.sumKpiReportStats.sum) *
                  100
                ).toFixed(0)}
            %
          </span>
          <span style={{ marginLeft: '0.1rem' }}>
            ({reportStats?.sumKpiReportStats?.green ?? 0})
          </span>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          className={'rag-letter-box gray-rag-box'}
          style={{ height: '1.2rem', width: '1.2rem' }}
        >
          <span class="icon-size"></span>
        </div>
        <div style={{ marginLeft: '0.3rem', display: 'flex', flexDirection: 'row', width: '60px' }}>
          <span>
            {!reportStats?.sumKpiReportStats?.sum || reportStats?.sumKpiReportStats?.sum <= 0
              ? '0'
              : (
                  (reportStats.sumKpiReportStats.grey / reportStats.sumKpiReportStats.sum) *
                  100
                ).toFixed(0)}
            %
          </span>
          <span style={{ marginLeft: '0.1rem' }}>
            ({reportStats?.sumKpiReportStats?.grey ?? 0})
          </span>
        </div>
      </div>
    </div>
  )
}

const KpiReportByLeader = ({ hideTitle = false, selectedDaysForShowingWorseKpis = null }) => {
  const { state } = useLocation()
  const { t } = useTranslation(['Common', 'Dashboard'])
  const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)
  // const showMeetingDeck = getCompanyConfigSettings('showMeetingDeck') === true

  const [areaLeaders, setAreaLeaders] = React.useState([])
  const [selectedLeader, setSelectedLeader] = React.useState(null)
  const [pieChartData, setPieChartData] = React.useState([])
  const [lineChartData, setLineChartData] = React.useState([])
  const [barChartData, setBarChartData] = React.useState([])

  const [l2KpiReportsStats, setL2KpiReportsStats] = React.useState({})
  const [bauKpiReportsStats, setBauKpiReportsStats] = React.useState({})

  const [l3KpiReportsStats, setL3KpiReportsStats] = React.useState(null)

  const [kpiReports, setKpiReports] = React.useState(null)
  const [bauKpiReports, setBauKpiReports] = React.useState(null)

  const [isLoading, setIsLoading] = React.useState(false)
  const [cardModalUmtId, setCardModalUmtId] = React.useState(0)
  const [isOpenCardModal, setIsOpenCardModal] = React.useState(false)
  const [cardModalTopicType, setCardModalTopicType] = React.useState(null)

  const [isL2Hidden, setIsL2Hidden] = React.useState({})

  const [isL3Hidden, setIsL3Hidden] = React.useState({})

  const [shouldCollapse, setShouldCollapse] = React.useState(false)

  const currentUser = localStorage.getItem('tfmu')

  const allowL2UsersSeePeerKpiReports =
    getConsolidatedConfigSettings('allow_l2_users_see_peer_kpi_reports') === true

  const [requestedLayer, setRequestedLayer] = React.useState(null)

  const [allKpiRights, setAllKpiRights] = React.useState([])
  const canView23Layers =
    hasRole('area') || allKpiRights.filter((emp) => emp.level === 2).length > 0
  const canViewALlLayers =
    hasRole('cxo') ||
    hasRole('cos') ||
    hasRole('ceo') ||
    allKpiRights.filter((emp) => emp.level === 1).length > 0

  const [isOpenAddEditKPIModal, setIsOpenAddEditKPIModal] = React.useState(false)
  const [addEditModalMode, setAddEditModalMode] = React.useState('')
  const [selectedKpi, setSelectedKpi] = React.useState(null)
  const [selectedObjId, setSelectedObjId] = React.useState(null)
  const [selectedKpiUpdate, setSelectedKpiUpdate] = React.useState(null)

  const [isOpenUpdateModal, setIsOpenUpdateModal] = React.useState(false)
  const [isOpenKPIUpdatesModal, setIsOpenKPIUpdatesModal] = React.useState(false)
  const [filtersSectionOpen, setFiltersSectionOpen] = React.useState(true)

  const defaultLayer = state?.level
    ? state.level - 1
    : hasRole('cxo') || hasRole('cos') || hasRole('ceo')
      ? 0
      : hasRole('area')
        ? 1
        : 2
  const [tabOption, setTabOption] = React.useState(defaultLayer)
  const [shouldShowCompleted, setShouldShowCompleted] = React.useState(false)

  const user_e_id = localStorage.getItem('tfei')

  const canSeeKpiL2 = getConsolidatedConfigSettings('enable_l2_kpi') === true

  const updateCollapaseState = (isHidden, rl = undefined) => {
    let l2States = {}
    let l3States = {}
    kpiReports?.forEach((l1KpiReport) => {
      l1KpiReport?.l2KpiReportsRaw?.forEach((l2KpiReport) => {
        // we should always keep l2 level open when at level 3
        let rLayer = rl ? rl : requestedLayer
        l2States[l2KpiReport.l2ObjId] = rLayer === 3 ? false : isHidden

        l2KpiReport?.l3KpiList.forEach((l3KpiReport) => {
          l3States[l3KpiReport.objId] = isHidden
        })
      })

      l1KpiReport?.bauKpiReportsRaw?.forEach((bauKpiReport) => {
        l2States[bauKpiReport.l2ObjId] = isHidden
        bauKpiReport?.l3KpiList.forEach((l3KpiReport) => {
          l3States[l3KpiReport.objId] = isHidden
        })
      })
    })
    // bau section
    setIsL2Hidden(l2States)
    setIsL3Hidden(l3States)
    setShouldCollapse(!isHidden)
  }

  React.useEffect(() => {
    if (requestedLayer && kpiReports) {
      // if it's from kpi table from report tab, we should expand all by default
      updateCollapaseState(selectedDaysForShowingWorseKpis === null, requestedLayer)
    }
  }, [requestedLayer, kpiReports])

  const toggleParentContent = (l2ObjId) => {
    setIsL2Hidden((prev) => {
      return {
        ...prev,
        [l2ObjId]: prev[l2ObjId] === undefined ? false : !prev[l2ObjId],
      }
    })
  }

  const toggleChildContent = (l3ObjId) => {
    setIsL3Hidden((prev) => {
      return {
        ...prev,
        [l3ObjId]: prev[l3ObjId] === undefined ? false : !prev[l3ObjId],
      }
    })
  }

  React.useEffect(() => {
    ;(async () => {
      try {
        if (selectedDaysForShowingWorseKpis !== null) {
          return
        }

        setIsLoading(true)
        let response = await getHttpRequest('/get_kpi_report_view_rights')
        setAllKpiRights(response?.employees ?? [])
      } catch (error) {}
    })()
  }, [])

  React.useEffect(() => {
    // (async () => {
    // setIsLoading(true)
    setL2KpiReportsStats({})
    setBauKpiReportsStats({})
    setPieChartData([])
    setLineChartData([])
    setBarChartData([])
    setKpiReports(null)
    setBauKpiReports(null)
    setRequestedLayer(null)
    setSelectedLeader(null)
    setColorFilterValue([])

    setAreaLeaders(
      allKpiRights
        .filter((emp) => {
          // L3 tab => shows all L3 & L2
          if (tabOption === 2) {
            return emp.level === tabOption || emp.level === tabOption + 1
          }
          return emp.level === tabOption + 1
        })
        .sort((a, b) => a.name.localeCompare(b.name)) ?? [],
    )
    if (tabOption === defaultLayer && allKpiRights.length > 0) {
      if (state?.leaderId) {
        setSelectedLeader(state.leaderId)
      } else {
        setSelectedLeader(
          allKpiRights.find(
            (employee) => employee.email.toLowerCase() === currentUser?.toLowerCase(),
          )?.eId,
        )
      }
    }
    // setIsLoading(false)
  }, [tabOption, allKpiRights])

  const handleOwnerChange = (e) => {
    setSelectedLeader(e?.eId)
  }

  React.useEffect(() => {
    if (selectedLeader) {
      setShouldCollapse(false)
      setIsL2Hidden({})
      setIsL3Hidden({})
      getL2KpiReport()
    }
  }, [selectedLeader])

  React.useEffect(() => {
    if (selectedDaysForShowingWorseKpis) {
      setShouldCollapse(false)
      setIsL2Hidden({})
      setIsL3Hidden({})
      getL2KpiReport()
    }
  }, [selectedDaysForShowingWorseKpis])

  const computePieChart = (l1KpiReportsRaw) => {
    let pieChartDataRaw = {
      red: 0,
      green: 0,
      yellow: 0,
      grey: 0,
    }
    let tempL2KpiReportsStats = {}
    let tempBauKpiReportsStats = {}

    for (let l1KpiObject of l1KpiReportsRaw ?? []) {
      for (let l2Stats of l1KpiObject.l2KpiReportsStats ?? []) {
        pieChartDataRaw.red += l2Stats.sumKpiReportStats.red
        pieChartDataRaw.green += l2Stats.sumKpiReportStats.green
        pieChartDataRaw.yellow += l2Stats.sumKpiReportStats.yellow
        pieChartDataRaw.grey += l2Stats.sumKpiReportStats.grey
      }

      pieChartDataRaw.red += l1KpiObject?.bauKpiReportsStats?.sumKpiReportStats?.red ?? 0
      pieChartDataRaw.green += l1KpiObject?.bauKpiReportsStats?.sumKpiReportStats?.green ?? 0
      pieChartDataRaw.yellow += l1KpiObject?.bauKpiReportsStats?.sumKpiReportStats?.yellow ?? 0
      pieChartDataRaw.grey += l1KpiObject?.bauKpiReportsStats?.sumKpiReportStats?.grey ?? 0

      tempL2KpiReportsStats[l1KpiObject.l1ObjId] = l1KpiObject.l2KpiReportsStats ?? []
      tempBauKpiReportsStats[l1KpiObject.l1ObjId] = l1KpiObject?.bauKpiReportsStats ?? {}
    }

    setL2KpiReportsStats(tempL2KpiReportsStats)
    setBauKpiReportsStats(tempBauKpiReportsStats)

    if (
      pieChartDataRaw.red +
        pieChartDataRaw.green +
        pieChartDataRaw.yellow +
        pieChartDataRaw.grey ===
      0
    ) {
      setPieChartData([])
      return
    }
    setPieChartData([
      {
        name: 'Red',
        value: pieChartDataRaw.red,
      },
      {
        name: 'Yellow',
        value: pieChartDataRaw.yellow,
      },
      {
        name: 'Green',
        value: pieChartDataRaw.green,
      },
      {
        name: 'Grey',
        value: pieChartDataRaw.grey,
      },
    ])
  }

  const computeLineChart = (l2KpiReportsLineChart) => {
    let lineChartPoints = []
    for (let dataPoint of l2KpiReportsLineChart ?? []) {
      lineChartPoints.push({
        date: dayMonthFilter(dataPoint.timestamp),
        value:
          dataPoint.totalKpi === 0.0
            ? 0.0
            : ((100.0 * dataPoint.totalGreenKpi) / dataPoint.totalKpi).toFixed(1),
        timestamp: dataPoint.timestamp,
      })
    }
    lineChartPoints.sort((a, b) => a.timestamp - b.timestamp)
    setLineChartData(lineChartPoints)
  }

  const getL2KpiReport = async () => {
    try {
      setIsLoading(true)
      let response
      if (selectedDaysForShowingWorseKpis !== null) {
        response = await getHttpRequest(`/get_kpi_report/${user_e_id}`, {
          params: {
            timeFrame: selectedDaysForShowingWorseKpis,
            onlyShowKpisThatAreWorsed: true,
            level: tabOption + 1,
          },
        })
      } else {
        response = await getHttpRequest(`/get_kpi_report/${selectedLeader}`, {
          params: {
            level: tabOption + 1,
            canSeeKpiL2: canSeeKpiL2,
          },
        })
      }

      computePieChart(response.l1KpiReportsRaw)
      computeLineChart(response.kpiReportsLineChart)
      setBarChartData(response.objectiveKpiCountsStackedBar)

      setKpiReports(response.l1KpiReportsRaw)

      setRequestedLayer(response.requestedLayer)
      setIsLoading(false)
      computeTagsList(response.l1KpiReportsRaw)
      computeKpiOwnerList(response.l1KpiReportsRaw)
    } catch (error) {
      setIsLoading(false)
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong! Please try again later.',
      })
    }
  }

  const convertEnumToTextUpdateValue = (enumValue) => {
    switch (enumValue) {
      case 0:
        return '---'
      case 1:
        return t('kpi.notStarted')
      case 2:
        return t('kpi.inProgress')
      case 3:
        return t('kpi.completed')
      default:
        return t('kpi.unknown')
    }
  }

  const convertFrequencyEnumToText = (enumValue) => {
    switch (enumValue) {
      case 1:
        return t('kpi.weekly')
      case 2:
        return t('kpi.biweekly')
      case 3:
        return t('kpi.monthly')
      case 4:
        return t('kpi.quarterly')
      default:
        return t('kpi.unknown')
    }
  }

  const [colorFilterValue, setColorFilterValue] = React.useState([])
  const [tagsFilterValue, setTagsFilterValue] = React.useState([])
  const [tagsFilterOptions, setTagsFilterOptions] = React.useState([])
  const [kpiOwnerOptions, setKpiOwnerOptions] = React.useState([])
  const [kpiOwnerValue, setKpiOwnerValue] = React.useState([])

  const colorFilterOptions = [
    { value: 'red', label: t('Common:ragColors.red') },
    { value: 'yellow', label: t('Common:ragColors.amber') },
    { value: 'green', label: t('Common:ragColors.green') },
    { value: 'grey', label: t('Common:ragColors.grey') },
  ]

  const handleChangeColorFilter = (value) => {
    setColorFilterValue(value)
  }

  const handleChangeTagsFilter = (value) => {
    if (value === null) {
      setTagsFilterValue([])
      return
    }
    setTagsFilterValue([value])
  }

  const handleChangeKpiOwnerFilter = (selectedOptions) => {
    setKpiOwnerValue(selectedOptions)
  }

  const computeTagsList = (l1KpiReportsRaw) => {
    const uniqueTags = new Map()

    const addTag = (tag) => {
      const tagObject = JSON.parse(tag)
      if (!uniqueTags.has(tagObject.tagId)) {
        uniqueTags.set(tagObject.tagId, tagObject)
      }
    }

    for (const l1KpiObject of l1KpiReportsRaw ?? []) {
      for (const l2KpiObject of l1KpiObject.l2KpiReportsRaw ?? []) {
        ;(l2KpiObject.tags ?? []).forEach((tag) => addTag(JSON.stringify(tag)))
        for (const l3KpiObject of l2KpiObject.l3KpiList ?? []) {
          ;(l3KpiObject.tags ?? []).forEach((tag) => addTag(JSON.stringify(tag)))
        }
      }
      for (const bauKpiObject of l1KpiObject.bauKpiReportsRaw ?? []) {
        ;(bauKpiObject.tags ?? []).forEach((tag) => addTag(JSON.stringify(tag)))
        for (const l3KpiObject of bauKpiObject.l3KpiList ?? []) {
          ;(l3KpiObject.tags ?? []).forEach((tag) => addTag(JSON.stringify(tag)))
        }
      }
    }

    const tagsList = Array.from(uniqueTags.values())
    tagsList.sort((a, b) => a.tagName.localeCompare(b.tagName))
    setTagsFilterOptions(tagsList)
  }

  const computeKpiOwnerList = (l1KpiReportsRaw) => {
    const uniquePeople = new Map()

    const addPerson = (kpi) => {
      const kpiObject = JSON.parse(kpi)
      if (!uniquePeople.has(kpiObject.ownerEId)) {
        uniquePeople.set(kpiObject.ownerEId, {
          name: kpiObject.ownerName,
          eId: kpiObject.ownerEId,
        })
      }
    }

    for (const l1KpiObject of l1KpiReportsRaw ?? []) {
      for (const l2KpiObject of l1KpiObject.l2KpiReportsRaw ?? []) {
        ;(l2KpiObject.kpis ?? []).forEach((kpi) => addPerson(JSON.stringify(kpi)))
        for (const l3KpiObject of l2KpiObject.l3KpiList ?? []) {
          ;(l3KpiObject.kpis ?? []).forEach((kpi) => addPerson(JSON.stringify(kpi)))
        }
      }
      for (const bauKpiObject of l1KpiObject.bauKpiReportsRaw ?? []) {
        ;(bauKpiObject.kpis ?? []).forEach((kpi) => addPerson(JSON.stringify(kpi)))
        for (const l3KpiObject of bauKpiObject.l3KpiList ?? []) {
          ;(l3KpiObject.kpis ?? []).forEach((kpi) => addPerson(JSON.stringify(kpi)))
        }
      }
    }

    const peopleList = Array.from(uniquePeople.values())
    peopleList.sort((a, b) => a.name.localeCompare(b.name))
    setKpiOwnerOptions(peopleList)
  }

  React.useEffect(() => {
    if (colorFilterValue.length > 0) {
      updateCollapaseState(false)
    } else {
      updateCollapaseState(true)
    }
  }, [colorFilterValue])

  const filterByWorseKpisExist = (stats) => {
    if (stats?.sum > 0) return true
    return false
  }

  const filterByColor = (stats) => {
    if (colorFilterValue.length === 0) {
      return true
    }
    for (let color of colorFilterValue) {
      if (color.value === 'red' && stats?.red > 0) {
        return true
      }
      if (color.value === 'green' && stats?.green > 0) {
        return true
      }
      if (color.value === 'yellow' && stats?.yellow > 0) {
        return true
      }
      if (color.value === 'grey' && stats?.grey > 0) {
        return true
      }
    }
    return false
  }

  const filterByTagsLevel3 = (obj) => {
    if (tagsFilterValue.length === 0) {
      return true
    }
    for (let tag of tagsFilterValue) {
      if (obj.tags.find((t) => t.tagId === tag.tagId)) {
        return true
      }
    }
    return false
  }

  const filterByTagsLevel2 = (obj) => {
    let pass = false
    if (tagsFilterValue.length === 0) {
      pass = true
    }

    obj.l3KpiList.forEach((l3Kpi) => {
      for (let tag of tagsFilterValue) {
        if (l3Kpi.tags.find((t) => t.tagId === tag.tagId)) {
          pass = true
        }
      }
    })
    return pass
  }

  const filterByTagsLevel1 = (obj) => {
    let pass = false
    if (tagsFilterValue.length === 0) {
      pass = true
    }

    obj.bauKpiReportsRaw.forEach((bauKpi) => {
      bauKpi.l3KpiList.forEach((l3Kpi) => {
        for (let tag of tagsFilterValue) {
          if (l3Kpi.tags.find((t) => t.tagId === tag.tagId)) {
            pass = true
          }
        }
      })
    })

    obj.l2KpiReportsRaw.forEach((l2Kpi) => {
      l2Kpi.l3KpiList.forEach((l3Kpi) => {
        for (let tag of tagsFilterValue) {
          if (l3Kpi.tags.find((t) => t.tagId === tag.tagId)) {
            pass = true
          }
        }
      })
    })
    return pass
  }

  const filterByKPIOwnerLevel3 = (obj) => {
    if (kpiOwnerValue.length === 0) {
      return true
    }
    for (let kpiOwner of kpiOwnerValue) {
      if (obj.kpis.find((t) => t.ownerEId === kpiOwner.eId)) {
        return true
      }
    }
    return false
  }

  const filterByKPIOwnerLevel2 = (obj) => {
    let pass = false
    if (kpiOwnerValue.length === 0) {
      pass = true
    }

    obj.l3KpiList.forEach((l3Kpi) => {
      for (let kpiOwner of kpiOwnerValue) {
        if (l3Kpi.kpis.find((t) => t.ownerEId === kpiOwner.eId)) {
          pass = true
        }
      }
    })
    return pass
  }

  const filterByKPIOwnerLevel1 = (obj) => {
    let pass = false
    if (kpiOwnerValue.length === 0) {
      pass = true
    }
    obj.bauKpiReportsRaw.forEach((bauKpi) => {
      bauKpi.l3KpiList.forEach((l3Kpi) => {
        for (let kpiOwner of kpiOwnerValue) {
          if (l3Kpi.kpis.find((t) => t.ownerEId === kpiOwner.eId)) {
            pass = true
          }
        }
      })
    })
    obj.l2KpiReportsRaw.forEach((l2Kpi) => {
      l2Kpi.l3KpiList.forEach((l3Kpi) => {
        for (let kpiOwner of kpiOwnerValue) {
          if (l3Kpi.kpis.find((t) => t.ownerEId === kpiOwner.eId)) {
            pass = true
          }
        }
      })
    })
    return pass
  }

  const filterByColorKpi = (kpi) => {
    if (colorFilterValue.length === 0) {
      return true
    }
    for (let color of colorFilterValue) {
      if (color.value === 'red' && kpi.ragStatusWhenOverdue === 2) {
        return true
      }
      if (color.value === 'green' && kpi.ragStatusWhenOverdue === 0) {
        return true
      }
      if (color.value === 'yellow' && kpi.ragStatusWhenOverdue === 1) {
        return true
      }
      if (color.value === 'grey' && kpi.ragStatusWhenOverdue === -1) {
        return true
      }
    }
    return false
  }

  async function onExportCSV() {
    const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)
    const url =
      BASE_URL +
      `/export_kpi_report_spread_sheet/${selectedLeader}?level=${
        tabOption + 1
      }&canSeeKpiL2=${canSeeKpiL2}`
    window.open(url, '_blank')
  }

  const resetFilter = () => {
    setTagsFilterValue([])
    setColorFilterValue([])
    setKpiOwnerValue([])
  }

  return (
    <>
      {isOpenCardModal && cardModalTopicType !== null && (
        <CardModal
          isModalOpen={isOpenCardModal}
          closeModal={() => {
            setIsOpenCardModal(false)
            setCardModalTopicType(null)
          }}
          umtId={cardModalUmtId}
          topicType={cardModalTopicType}
        />
      )}
      {isOpenAddEditKPIModal && selectedObjId && selectedKpi && (
        <AddEditKPIModal
          closeModal={() => {
            setIsOpenAddEditKPIModal(false)
            setSelectedObjId(null)
            setSelectedKpi(null)
          }}
          mode={addEditModalMode}
          objId={selectedObjId}
          kpi={selectedKpi}
          reload={() => {
            getL2KpiReport()
          }}
        />
      )}
      {isOpenKPIUpdatesModal && selectedObjId && selectedKpiUpdate && (
        <KPIUpdatesModal
          handleCancel={() => {
            setIsOpenKPIUpdatesModal(false)
            setSelectedObjId(null)
            setSelectedKpiUpdate(null)
            getL2KpiReport()
          }}
          reloadBackground={() => {
            getL2KpiReport()
          }}
          selectedKpiUpdate={selectedKpiUpdate}
          objId={selectedObjId}
          canAddUpdate={selectedKpiUpdate.isCompleted === false}
          canEditUpdate={selectedKpiUpdate.isCompleted === false}
        />
      )}

      {isOpenUpdateModal && selectedObjId && selectedKpiUpdate && (
        <UpdateKPIModal
          closeModal={() => {
            setIsOpenUpdateModal(false)
            setSelectedObjId(null)
            setSelectedKpiUpdate(null)
          }}
          objId={selectedObjId}
          kpi={selectedKpiUpdate}
          loadUpdates={() => {
            getL2KpiReport()
          }}
        />
      )}
      <div
        style={{
          width: '90%',
          paddingTop: selectedDaysForShowingWorseKpis ? '0rem' : '3rem',
          paddingLeft: '1rem',
        }}
      >
        {!hideTitle && (
          <>
            <div style={{ textAlign: 'left' }}>
              <b style={{ justifySelf: 'flex-start', alignSelf: 'center', fontSize: '20px' }}>
                {t('kpi.kpiReportTitle')}
              </b>
            </div>
            <div className="divider" style={{ marginBottom: '0.4rem', marginTop: '1rem' }}></div>
          </>
        )}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {selectedDaysForShowingWorseKpis === null && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  columnGap: '1rem',
                  marginTop: '1rem',
                }}
              >
                <div className="kpi-report-nav-container">
                  {canViewALlLayers && (
                    <div
                      className={`nav-option-kpi-report ${tabOption === 0 ? 'active' : ''}`}
                      onClick={() => setTabOption(0)}
                    >
                      <span className="nav-option-text-kpi-report">{t('kpi.kpiReportL1')}</span>
                    </div>
                  )}
                  {(canViewALlLayers || canView23Layers) && (
                    <div
                      className={`nav-option-kpi-report ${tabOption === 1 ? 'active' : ''}`}
                      onClick={() => setTabOption(1)}
                    >
                      <span className="nav-option-text-kpi-report">{t('kpi.kpiReportL2')}</span>
                    </div>
                  )}
                  {/* don;t show L3 kpis if  canSeeKpiL2*/}
                  {!canSeeKpiL2 && (
                    <div
                      className={`nav-option-kpi-report ${tabOption === 2 ? 'active' : ''}`}
                      onClick={() => setTabOption(2)}
                    >
                      <span className="nav-option-text-kpi-report">{t('kpi.kpiReportL3')}</span>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    marginLeft: 'auto',
                    display: 'flex',
                    flexDirection: 'row',
                    columnGap: '1rem',
                  }}
                >
                  <div style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                    <b>{t('kpi.selectLeader')}</b>
                  </div>
                  <SelectSingleField
                    placeholder={t('formPlaceHolder.selectLeader')}
                    name="selectLeader"
                    value={areaLeaders?.filter(
                      (person) => parseInt(person.eId) === parseInt(selectedLeader),
                    )}
                    options={areaLeaders}
                    onChange={handleOwnerChange}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.eId}
                  />
                </div>
              </div>
              <div className="divider" style={{ marginBottom: '0.4rem', marginTop: '1rem' }}></div>
            </>
          )}

          {!isLoading && selectedLeader && selectedDaysForShowingWorseKpis === null && (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {barChartData.length > 0 && <StackedBarCustomizedLabel data={barChartData} />}
              {pieChartData.length > 0 ? (
                <PieCharCustomizedLabel data={pieChartData} kpi={true} />
              ) : (
                <p
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: 'auto',
                    marginTop: 'auto',
                  }}
                >
                  {t('Dashboard:chartsCard.notChartData')}
                </p>
              )}
              {lineChartData.length > 0 && <LineChartCustomizedLabel data={lineChartData} />}
            </div>
          )}

          {isLoading && <InPageLoader />}
          {!isLoading && selectedLeader === null && selectedDaysForShowingWorseKpis === null && (
            <div style={{ textAlign: 'center', marginTop: '2rem' }}>
              {t('kpi.selectLeaderToViewKpiReport')}
            </div>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              gap: '1rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '1rem',
                paddingLeft: '0.8rem',
                justifyContent: 'flex-end',
              }}
            >
              {!isLoading &&
                selectedLeader &&
                selectedDaysForShowingWorseKpis === null &&
                kpiReports?.length > 0 && (
                  <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                    <div
                      class="fitted-button blue"
                      style={{ alignSelf: 'end', marginRight: '0.5rem' }}
                      onClick={onExportCSV}
                    >
                      <span class="material-symbols-outlined">download</span>
                    </div>
                  </div>
                )}

              {!shouldCollapse && (
                <div
                  className="sleek-button sleek-blue"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    updateCollapaseState(false)
                  }}
                >
                  {t('Common:commonButtons.expandAll')}
                </div>
              )}

              {shouldCollapse && (
                <div
                  className="sleek-button sleek-blue"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    updateCollapaseState(true)
                  }}
                >
                  {t('Common:commonButtons.collapseAll')}
                </div>
              )}
              <div
                className="sleek-button sleek-blue"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setShouldShowCompleted((prev) => !prev)
                }}
              >
                {shouldShowCompleted ? t('kpi.showUncompleted') : t('kpi.showCompleted')}
              </div>

              <div
                className="sleek-button sleek-blue"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setFiltersSectionOpen(!filtersSectionOpen)
                }}
              >
                <span class="material-symbols-outlined icon-size-l">filter_alt</span>
                {t('Common:filter.filters')}
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                <div
                  className="sleek-button sleek-blue"
                  onClick={() => {
                    resetFilter()
                  }}
                  //style={{ fontSize: '12px', height: '1.8rem' }}
                >
                  <span>{t('Common:commonButtons.clearFilters')}</span>
                </div>
              </div>
            </div>

            {filtersSectionOpen && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '1rem',
                  paddingLeft: '0.8rem',
                  justifyContent: 'flex-end',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
                  <label for="teamLeadersFilter">
                    <span style={{ fontSize: '14px' }}>{t('Common:filter.kpiStatus')}:</span>
                  </label>
                  <div data-testid="multi-options-1" style={{ width: '20vw' }}>
                    <Select
                      placeholder={t('Common:filter.select') + '...'}
                      value={colorFilterValue}
                      isMulti
                      onChange={handleChangeColorFilter}
                      getOptionLabel={(option) => option?.label}
                      getOptionValue={(option) => option?.value}
                      options={colorFilterOptions}
                    />
                  </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
                  <label for="teamLeadersFilter">
                    <span style={{ fontSize: '14px' }}>{t('Common:filter.tags')}:</span>
                  </label>
                  <div data-testid="multi-options-1" style={{ width: '20vw' }}>
                    <Select
                      placeholder={t('Common:formPlaceHolder.select') + '...'}
                      value={tagsFilterValue}
                      isMulti={false}
                      onChange={handleChangeTagsFilter}
                      getOptionLabel={(option) => option?.tagName}
                      getOptionValue={(option) => option?.tagId}
                      options={tagsFilterOptions}
                      isClearable={true}
                    />
                  </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
                  <label for="teamLeadersFilter">
                    <span style={{ fontSize: '14px' }}>{t('Common:filter.kpiOwner')}:</span>
                  </label>
                  <div data-testid="multi-options-1" style={{ width: '20vw' }}>
                    <Select
                      placeholder={t('Common:filter.select') + '...'}
                      value={kpiOwnerValue}
                      isMulti={true}
                      onChange={handleChangeKpiOwnerFilter}
                      getOptionLabel={(option) => option?.name}
                      getOptionValue={(option) => option?.eId}
                      options={kpiOwnerOptions}
                      isClearable={true}
                    />
                  </div>
                </div>
              </div>
            )}

            <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '0.5rem',
                  paddingLeft: '0.8rem',
                }}
              ></div>
            </div>

            {!isLoading &&
              (selectedLeader || selectedDaysForShowingWorseKpis) &&
              kpiReports?.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '40%',
                    columnGap: '2rem',
                    color: '#0926D5',
                  }}
                >
                  <span
                    style={{
                      color: 'black',
                      fontSize: '14px',
                      flexDirection: 'row',
                      display: 'flex',
                    }}
                  >
                    <span style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                      <span class="material-symbols-outlined icon-size">distance</span>
                      {t('Common:legend.milestoneKpis')}
                    </span>
                    {/* <span style={{ display: 'flex', alignItems: 'center' }}>
                      {' '}
                      <span class="material-symbols-outlined icon-size">tag</span>
                      {t('Common:legend.numericKpis')}
                    </span> */}
                  </span>
                </div>
              )}
          </div>
          {!isLoading && (
            <>
              {kpiReports &&
                (selectedLeader || selectedDaysForShowingWorseKpis) &&
                kpiReports
                  .sort((a, b) => a.l1ObjDescription.localeCompare(b.l1ObjDescription))
                  .map((l1KpiReport, l1Index) => {
                    if (
                      filterByTagsLevel1(l1KpiReport) === false ||
                      filterByKPIOwnerLevel1(l1KpiReport) === false
                    ) {
                      return null
                    }
                    if (
                      selectedDaysForShowingWorseKpis !== null &&
                      filterByWorseKpisExist(l1KpiReport?.l1KpiReportStats?.sumKpiReportStats) ===
                        false &&
                      tabOption == 0
                    ) {
                      return null
                    }
                    if (
                      filterByColor(l1KpiReport?.l1KpiReportStats?.sumKpiReportStats) === false &&
                      tabOption == 0
                    )
                      return null
                    else
                      return (
                        <div
                          className={`${requestedLayer === 1 ? 'shadow-kpi-report' : 'dummy'}`}
                          key={l1KpiReport.l1ObjId}
                          style={{
                            padding: '0.8rem',
                            marginBottom: requestedLayer === 1 ? '1rem' : '0rem',
                            marginTop: requestedLayer === 1 ? '1rem' : '0rem',
                          }}
                        >
                          {requestedLayer === 1 && (
                            <>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  width: '100%',
                                  padding: '0.3rem',
                                }}
                              >
                                <div
                                  style={{ display: 'flex', width: hideTitle ? '66.7%' : '67.7%' }}
                                >
                                  <span
                                    style={{ display: 'flex', alignItems: 'center', width: '90%' }}
                                  >
                                    <ObjectiveStatement>
                                      {l1KpiReport.l1ObjDescription}
                                    </ObjectiveStatement>
                                  </span>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginLeft: 'auto',
                                  }}
                                >
                                  <span
                                    class=" tooltip"
                                    style={{ marginLeft: '0.5rem', marginRight: '0.2rem' }}
                                  >
                                    <NameBox>{l1KpiReport.l1ObjOwnerName.match(/\b(\w)/g)}</NameBox>
                                    <span class="tooltiptext">{l1KpiReport.l1ObjOwnerName} </span>
                                  </span>
                                  <RagList reportStats={l1KpiReport.l1KpiReportStats} />
                                </div>
                              </div>
                              <div
                                className="divider"
                                style={{ marginBottom: '0.4rem', marginLeft: '0.5rem' }}
                              ></div>
                            </>
                          )}
                          {l1KpiReport.l2KpiReportsRaw
                            .sort((a, b) => a.l2ObjDescription.localeCompare(b.l2ObjDescription))
                            .map((l2KpiReport, l2Index) => {
                              let l2Stats = l2KpiReportsStats?.[l1KpiReport.l1ObjId]?.find(
                                (l2Stats) => l2Stats.objId === l2KpiReport.l2ObjId,
                              )
                              if (
                                filterByTagsLevel2(l2KpiReport) === false ||
                                filterByKPIOwnerLevel2(l2KpiReport) === false
                              ) {
                                return null
                              }
                              if (
                                selectedDaysForShowingWorseKpis !== null &&
                                filterByWorseKpisExist(l2Stats?.sumKpiReportStats) === false
                              ) {
                                return null
                              }

                              if (
                                filterByColor(l2Stats?.sumKpiReportStats) === false &&
                                tabOption == 1
                              )
                                return null
                              else
                                return (
                                  <div
                                    className={`${requestedLayer !== 1 ? 'shadow-kpi-report' : ''}`}
                                    style={{
                                      width: '100%',
                                      marginBottom: requestedLayer !== 1 ? '1rem' : '0rem',
                                      padding: requestedLayer !== 1 ? '0.8rem' : '0.3rem',
                                    }}
                                    key={l2KpiReport.l2ObjId}
                                  >
                                    <div>
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          columnGap: '1rem',
                                          justifyContent: 'space-between',
                                          paddingRight: '0rem',
                                          paddingTop: '0.3rem',
                                          paddingBottom: '0.3rem',
                                        }}
                                      >
                                        {requestedLayer !== 3 && (
                                          <div
                                            style={{
                                              display: 'flex',
                                              flexDirection: 'row',
                                              width: '100%',
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                              }}
                                            >
                                              {(isL2Hidden[l2KpiReport.l2ObjId] === undefined ||
                                                isL2Hidden[l2KpiReport.l2ObjId]) && (
                                                <span
                                                  class="material-symbols-outlined"
                                                  onClick={() => {
                                                    toggleParentContent(l2KpiReport.l2ObjId)
                                                  }}
                                                  style={{ fontSize: '30px', cursor: 'pointer' }}
                                                >
                                                  arrow_drop_down
                                                </span>
                                              )}

                                              {isL2Hidden[l2KpiReport.l2ObjId] !== undefined &&
                                                !isL2Hidden[l2KpiReport.l2ObjId] && (
                                                  <span
                                                    class="material-symbols-outlined"
                                                    onClick={() => {
                                                      toggleParentContent(l2KpiReport.l2ObjId)
                                                    }}
                                                    style={{ fontSize: '30px', cursor: 'pointer' }}
                                                  >
                                                    arrow_drop_up
                                                  </span>
                                                )}
                                            </div>
                                            <div
                                              style={{
                                                display: 'flex',
                                                width: hideTitle ? '64.2%' : '65.3%',
                                              }}
                                            >
                                              <span
                                                style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  width: '90%',
                                                }}
                                              >
                                                <ObjectiveStatement>
                                                  {l2KpiReport.l2ObjDescription}
                                                </ObjectiveStatement>
                                              </span>
                                            </div>
                                            <div
                                              style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                marginLeft: 'auto',
                                              }}
                                            >
                                              <span
                                                class=" tooltip"
                                                style={{
                                                  marginLeft: '0.5rem',
                                                  marginRight: '0.2rem',
                                                }}
                                              >
                                                <NameBox>
                                                  {l2KpiReport.l2ObjOwnerName.match(/\b(\w)/g)}
                                                </NameBox>
                                                <span class="tooltiptext">
                                                  {l2KpiReport.l2ObjOwnerName}{' '}
                                                </span>
                                              </span>
                                              <RagList reportStats={l2Stats} />
                                            </div>
                                          </div>
                                        )}

                                        <div
                                          style={{
                                            display: 'flex',
                                            width: '10%',
                                            justifyContent: 'flex-end',
                                            alignItems: 'flex-start',
                                            //columnWidth: '0.3rem',
                                            columnGap: '0.3rem',
                                          }}
                                        >
                                          <div
                                            style={{
                                              //marginTop: '0.3rem',
                                              display: 'flex',
                                              flexDirection: 'row',
                                              columnGap: '1rem',
                                              justifyContent: 'space-between',
                                              //wordBreak: 'break-all',
                                              //width:"3rem"
                                            }}
                                          ></div>
                                        </div>

                                        {isL2Hidden[l2KpiReport.l2ObjId] !== undefined &&
                                          !isL2Hidden[l2KpiReport.l2ObjId] && (
                                            <>
                                              <div
                                                className="milestones-container"
                                                style={{ fontSize: '14px' }}
                                              >
                                                <React.Fragment key={`obj-${l2Index}`}>
                                                  <div>
                                                    {requestedLayer !== 3 && (
                                                      <div
                                                        className="divider"
                                                        style={{ marginBottom: '0.4rem' }}
                                                      ></div>
                                                    )}
                                                    {l2KpiReport.l3KpiList
                                                      .sort(
                                                        (a, b) =>
                                                          a.isObjShared - b.isObjShared ||
                                                          a.objDescription.localeCompare(
                                                            b.objDescription,
                                                          ),
                                                      )
                                                      .map((l3KpiReport, l3Index) => {
                                                        let l3Stats =
                                                          l2Stats.childrenKpiReportStats.find(
                                                            (l3KpiReportStats) =>
                                                              l3KpiReportStats.objId ===
                                                              l3KpiReport.objId,
                                                          )
                                                        if (
                                                          filterByTagsLevel3(l3KpiReport) ===
                                                            false ||
                                                          filterByKPIOwnerLevel3(l3KpiReport) ===
                                                            false
                                                        ) {
                                                          return null
                                                        }
                                                        if (
                                                          selectedDaysForShowingWorseKpis !==
                                                            null &&
                                                          filterByWorseKpisExist(
                                                            l3Stats?.sumKpiReportStats,
                                                          ) === false
                                                        ) {
                                                          return null
                                                        }
                                                        if (
                                                          filterByColor(
                                                            l3Stats?.sumKpiReportStats,
                                                          ) === false
                                                        )
                                                          return null
                                                        else
                                                          return (
                                                            <>
                                                              <div
                                                                style={{
                                                                  display: 'flex',
                                                                  flexDirection: 'column',
                                                                  columnGap: '1rem',
                                                                  justifyContent: 'space-between',
                                                                  paddingRight: '0rem',
                                                                  paddingTop: '0.3rem',
                                                                  paddingBottom: '0.3rem',
                                                                  // marginLeft:'1%',
                                                                  backgroundColor:
                                                                    l3Index % 2 === 0
                                                                      ? '#f5f5f5'
                                                                      : 'white',
                                                                }}
                                                                key={l3KpiReport.objId}
                                                              >
                                                                <div
                                                                  style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    width: '100%',
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      display: 'flex',
                                                                      justifyContent: 'flex-start',
                                                                      marginLeft: '1%',
                                                                    }}
                                                                  >
                                                                    {(isL3Hidden[
                                                                      l3KpiReport.objId
                                                                    ] === undefined ||
                                                                      isL3Hidden[
                                                                        l3KpiReport.objId
                                                                      ]) && (
                                                                      <span
                                                                        class="material-symbols-outlined"
                                                                        onClick={() => {
                                                                          toggleChildContent(
                                                                            l3KpiReport.objId,
                                                                          )
                                                                        }}
                                                                        style={{
                                                                          fontSize: '30px',
                                                                          cursor: 'pointer',
                                                                        }}
                                                                      >
                                                                        arrow_drop_down
                                                                      </span>
                                                                    )}

                                                                    {isL3Hidden[
                                                                      l3KpiReport.objId
                                                                    ] !== undefined &&
                                                                      !isL3Hidden[
                                                                        l3KpiReport.objId
                                                                      ] && (
                                                                        <span
                                                                          class="material-symbols-outlined"
                                                                          onClick={() => {
                                                                            toggleChildContent(
                                                                              l3KpiReport.objId,
                                                                            )
                                                                          }}
                                                                          style={{
                                                                            fontSize: '30px',
                                                                            cursor: 'pointer',
                                                                          }}
                                                                        >
                                                                          arrow_drop_up
                                                                        </span>
                                                                      )}
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      display: 'flex',
                                                                      width: '65%',
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        width: '90%',
                                                                      }}
                                                                    >
                                                                      <ObjectiveStatement>
                                                                        {l3KpiReport.isObjShared
                                                                          ? t('Common:shared') +
                                                                            ' - '
                                                                          : ''}
                                                                        {l3KpiReport.objDescription}
                                                                      </ObjectiveStatement>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      marginLeft: 'auto',
                                                                      display: 'flex',
                                                                      flexDirection: 'row',
                                                                    }}
                                                                  >
                                                                    <span
                                                                      class=" tooltip"
                                                                      style={{
                                                                        marginLeft: '0.5rem',
                                                                        marginRight: '0.2rem',
                                                                      }}
                                                                    >
                                                                      <NameBox>
                                                                        {l3KpiReport.objOwnerName.match(
                                                                          /\b(\w)/g,
                                                                        )}
                                                                      </NameBox>
                                                                      <span class="tooltiptext">
                                                                        {
                                                                          l3KpiReport.objOwnerName
                                                                        }{' '}
                                                                      </span>
                                                                    </span>
                                                                    <RagList
                                                                      reportStats={l3Stats}
                                                                    />
                                                                  </div>
                                                                </div>
                                                                {isL3Hidden[l3KpiReport.objId] !==
                                                                  undefined &&
                                                                  !isL3Hidden[
                                                                    l3KpiReport.objId
                                                                  ] && (
                                                                    <>
                                                                      <div
                                                                        className="divider"
                                                                        style={{
                                                                          marginBottom: '0.4rem',
                                                                        }}
                                                                      ></div>
                                                                      {l3KpiReport.kpis?.length >
                                                                      0 ? (
                                                                        <div
                                                                          style={{
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            gap: '0.5rem',
                                                                            marginLeft: '0.8rem',
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              marginBottom:
                                                                                '0.2rem',
                                                                              paddingTop: '1rem',
                                                                            }}
                                                                          >
                                                                            <div
                                                                              className={
                                                                                'kpi-status-table-l3-new'
                                                                              }
                                                                            >
                                                                              {/* <div className="chart-log-meeting-header ">
                                                                                    </div> */}
                                                                              <div className="chart-log-meeting-header-kpi "></div>
                                                                              <div className="chart-log-meeting-header-kpi ">
                                                                                {t('kpi.name')}
                                                                              </div>
                                                                              <div className="chart-log-meeting-header-kpi">
                                                                                {t('kpi.target')}
                                                                              </div>
                                                                              {/* <div className="chart-log-meeting-header-kpi">
                                                                                {t('kpi.reachBy')}
                                                                              </div> */}
                                                                              <div className="chart-log-meeting-header-kpi">
                                                                                {t(
                                                                                  'kpi.latestValue',
                                                                                )}
                                                                              </div>

                                                                              <div className="chart-log-meeting-header-kpi">
                                                                                {t('kpi.madeBy')}
                                                                              </div>
                                                                              <div className="chart-log-meeting-header-kpi">
                                                                                {t('kpi.notes')}
                                                                              </div>
                                                                              <div className="chart-log-meeting-header-kpi">
                                                                                {t('kpi.ownerName')}
                                                                              </div>
                                                                              <div className="chart-log-meeting-header-kpi">
                                                                                {t('kpi.status')}
                                                                              </div>
                                                                              <div className="chart-log-meeting-header-kpi">
                                                                                {t('kpi.topic')}
                                                                              </div>

                                                                              <div className="chart-log-meeting-header-kpi">
                                                                                {t('kpi.frequency')}
                                                                              </div>

                                                                              <div className="chart-log-meeting-header-kpi">
                                                                                {t('kpi.updatedOn')}
                                                                                <br />
                                                                                <span
                                                                                  style={{
                                                                                    fontSize:
                                                                                      '0.8rem',
                                                                                  }}
                                                                                >
                                                                                  (MM/DD/YY)
                                                                                </span>
                                                                              </div>
                                                                              <div className="chart-log-meeting-header-kpi"></div>

                                                                              {l3KpiReport.kpis
                                                                                ?.filter((kpi) => {
                                                                                  if (
                                                                                    shouldShowCompleted
                                                                                  ) {
                                                                                    return true
                                                                                  }
                                                                                  return !kpi.isCompleted
                                                                                })
                                                                                ?.sort((a, b) => {
                                                                                  if (
                                                                                    a.isCompleted &&
                                                                                    !b.isCompleted
                                                                                  ) {
                                                                                    return 1
                                                                                  }
                                                                                  if (
                                                                                    !a.isCompleted &&
                                                                                    b.isCompleted
                                                                                  ) {
                                                                                    return -1
                                                                                  }
                                                                                  return 0
                                                                                })
                                                                                ?.map(
                                                                                  (
                                                                                    kpi,
                                                                                    kpiIndex,
                                                                                  ) => {
                                                                                    let latestKpiUpdate =
                                                                                      kpi?.latestKpiUpdate
                                                                                    if (
                                                                                      filterByColorKpi(
                                                                                        kpi,
                                                                                      ) === false
                                                                                    )
                                                                                      return null
                                                                                    else
                                                                                      return (
                                                                                        <>
                                                                                          <React.Fragment
                                                                                            key={`objective-${kpi.kpiId}`}
                                                                                          >
                                                                                            {/* <div className="chart-log-meeting-description"></div> */}
                                                                                            <>
                                                                                              <div
                                                                                                className="kpi-status-table-cell"
                                                                                                style={{
                                                                                                  textAlign:
                                                                                                    'left',
                                                                                                  display:
                                                                                                    'flex',
                                                                                                  flexDirection:
                                                                                                    'row',
                                                                                                }}
                                                                                              >
                                                                                                <span class="material-symbols-outlined icon-size">
                                                                                                  {kpi.kpiType ===
                                                                                                  3
                                                                                                    ? 'distance'
                                                                                                    : ''}
                                                                                                </span>
                                                                                                {kpi.isCompleted ? (
                                                                                                  <span
                                                                                                    className="material-symbols-outlined"
                                                                                                    style={{
                                                                                                      color:
                                                                                                        'green',
                                                                                                    }}
                                                                                                  >
                                                                                                    check
                                                                                                  </span>
                                                                                                ) : null}
                                                                                              </div>
                                                                                              <div
                                                                                                className="kpi-status-table-cell"
                                                                                                style={{
                                                                                                  textAlign:
                                                                                                    'left',
                                                                                                  display:
                                                                                                    'flex',
                                                                                                  flexDirection:
                                                                                                    'row',
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  {
                                                                                                    kpi.name
                                                                                                  }
                                                                                                </span>
                                                                                              </div>
                                                                                              <div
                                                                                                className="kpi-status-table-cell"
                                                                                                style={{
                                                                                                  textAlign:
                                                                                                    'left',
                                                                                                }}
                                                                                              >
                                                                                                {kpi.unit && (
                                                                                                  <>
                                                                                                    {kpi.kpiType ===
                                                                                                    3
                                                                                                      ? ''
                                                                                                      : kpi.target.toLocaleString()}{' '}
                                                                                                    {
                                                                                                      kpi.unit
                                                                                                    }{' '}
                                                                                                  </>
                                                                                                )}
                                                                                                {t(
                                                                                                  'Common:kpi.by',
                                                                                                )}
                                                                                                <br />
                                                                                                <span
                                                                                                  style={{
                                                                                                    wordBreak:
                                                                                                      'keep-all',
                                                                                                  }}
                                                                                                >
                                                                                                  {
                                                                                                    kpi.targetDate
                                                                                                  }
                                                                                                </span>
                                                                                              </div>
                                                                                              {/* <div
                                                                                                className="kpi-status-table-cell"
                                                                                                style={{
                                                                                                  textAlign:
                                                                                                    'left',
                                                                                                }}
                                                                                              >
                                                                                                {kpi.targetDate.slice(
                                                                                                  0,
                                                                                                  kpi.targetDate.lastIndexOf(
                                                                                                    '/',
                                                                                                  ),
                                                                                                )}
                                                                                              </div> */}
                                                                                              <div
                                                                                                className="kpi-status-table-cell"
                                                                                                style={{
                                                                                                  textAlign:
                                                                                                    'left',
                                                                                                }}
                                                                                              >
                                                                                                {kpi.kpiType ===
                                                                                                3
                                                                                                  ? convertEnumToTextUpdateValue(
                                                                                                      kpi.lastUpdateAmount,
                                                                                                    )
                                                                                                  : kpi.hasKpiUpdates
                                                                                                    ? kpi.lastUpdateAmount.toLocaleString()
                                                                                                    : '---'}
                                                                                              </div>
                                                                                              <div
                                                                                                className="kpi-status-table-cell"
                                                                                                style={{
                                                                                                  textAlign:
                                                                                                    'left',
                                                                                                }}
                                                                                              >
                                                                                                {kpi.lastUpdateCreatedBy ===
                                                                                                ''
                                                                                                  ? '---'
                                                                                                  : truncateName(
                                                                                                      shortenName(
                                                                                                        kpi.lastUpdateCreatedBy,
                                                                                                      ),
                                                                                                    )}
                                                                                              </div>
                                                                                              <div
                                                                                                className="kpi-status-table-cell"
                                                                                                style={{
                                                                                                  textAlign:
                                                                                                    'left',
                                                                                                }}
                                                                                              >
                                                                                                {kpi.lastUpdateComment !==
                                                                                                ''
                                                                                                  ? truncateName(
                                                                                                      kpi?.lastUpdateComment,
                                                                                                      40,
                                                                                                    )
                                                                                                  : '---'}
                                                                                              </div>
                                                                                              <div
                                                                                                className="kpi-status-table-cell"
                                                                                                style={{
                                                                                                  textAlign:
                                                                                                    'left',
                                                                                                  display:
                                                                                                    'flex',
                                                                                                  flexDirection:
                                                                                                    'row',
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  {truncateName(
                                                                                                    shortenName(
                                                                                                      kpi.ownerName,
                                                                                                    ),
                                                                                                  )}
                                                                                                </span>
                                                                                              </div>
                                                                                              <div className="kpi-status-table-cell">
                                                                                                <div
                                                                                                  style={{
                                                                                                    backgroundColor:
                                                                                                      kpi.ragStatus ===
                                                                                                        -1 ||
                                                                                                      kpi.ragStatusWhenOverdue ===
                                                                                                        -1
                                                                                                        ? 'lightgray'
                                                                                                        : kpi.ragStatus ===
                                                                                                            0
                                                                                                          ? 'green'
                                                                                                          : kpi.ragStatus ===
                                                                                                              1
                                                                                                            ? 'gold'
                                                                                                            : 'red',
                                                                                                    width:
                                                                                                      '2rem',
                                                                                                    height:
                                                                                                      '2rem',
                                                                                                    // marginLeft:"1rem"
                                                                                                  }}
                                                                                                ></div>
                                                                                              </div>
                                                                                              <div
                                                                                                className="kpi-status-table-cell"
                                                                                                style={{
                                                                                                  display:
                                                                                                    'flex',
                                                                                                  flexDirection:
                                                                                                    'row',
                                                                                                  gap: '1rem',
                                                                                                  textAlign:
                                                                                                    'left',
                                                                                                }}
                                                                                              >
                                                                                                <div
                                                                                                  style={{
                                                                                                    textAlign:
                                                                                                      'left',
                                                                                                  }}
                                                                                                >
                                                                                                  {(kpi.ragStatus ===
                                                                                                    0 ||
                                                                                                    kpi.ragStatus ===
                                                                                                      -1) && (
                                                                                                    <span>
                                                                                                      {
                                                                                                        '---'
                                                                                                      }
                                                                                                    </span>
                                                                                                  )}
                                                                                                  {kpi.lastUpdateUmtId >
                                                                                                    0 &&
                                                                                                    (kpi.ragStatus ===
                                                                                                      1 ||
                                                                                                      kpi.ragStatus ===
                                                                                                        2) && (
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            'blue',
                                                                                                          textDecoration:
                                                                                                            'underline',
                                                                                                          cursor:
                                                                                                            'pointer',
                                                                                                        }}
                                                                                                        onClick={() => {
                                                                                                          setCardModalUmtId(
                                                                                                            kpi.lastUpdateUmtId,
                                                                                                          )

                                                                                                          if (
                                                                                                            kpi.ragStatus ===
                                                                                                            1
                                                                                                          ) {
                                                                                                            setCardModalTopicType(
                                                                                                              2,
                                                                                                            )
                                                                                                          } else if (
                                                                                                            kpi.ragStatus ===
                                                                                                            2
                                                                                                          ) {
                                                                                                            setCardModalTopicType(
                                                                                                              1,
                                                                                                            )
                                                                                                          }

                                                                                                          setIsOpenCardModal(
                                                                                                            true,
                                                                                                          )
                                                                                                        }}
                                                                                                      >
                                                                                                        {kpi.ragStatus ===
                                                                                                        1
                                                                                                          ? t(
                                                                                                              'kpi.seeGuidance',
                                                                                                            )
                                                                                                          : kpi.ragStatus ===
                                                                                                              2
                                                                                                            ? t(
                                                                                                                'kpi.seeBlocker',
                                                                                                              )
                                                                                                            : '---'}
                                                                                                      </span>
                                                                                                    )}
                                                                                                  {/* {latestKpiUpdate === undefined && (<span>{'---'}</span>)} */}
                                                                                                </div>
                                                                                              </div>

                                                                                              <div
                                                                                                className="kpi-status-table-cell"
                                                                                                style={{
                                                                                                  display:
                                                                                                    'flex',
                                                                                                  flexDirection:
                                                                                                    'row',
                                                                                                  gap: '1rem',
                                                                                                  textAlign:
                                                                                                    'left',
                                                                                                }}
                                                                                              >
                                                                                                <div
                                                                                                  style={{
                                                                                                    textAlign:
                                                                                                      'left',
                                                                                                  }}
                                                                                                >
                                                                                                  {convertFrequencyEnumToText(
                                                                                                    kpi.frequency,
                                                                                                  )}
                                                                                                </div>

                                                                                                {/* {latestKpiUpdate === undefined && (<span>{'---'}</span>)} */}
                                                                                              </div>

                                                                                              <div
                                                                                                className="kpi-status-table-cell"
                                                                                                style={{
                                                                                                  textAlign:
                                                                                                    'left',
                                                                                                  color:
                                                                                                    kpi.isKpiUpdateOverdue &&
                                                                                                    kpi.ragStatus !==
                                                                                                      -1
                                                                                                      ? 'red'
                                                                                                      : 'black',
                                                                                                }}
                                                                                              >
                                                                                                {kpi.hasKpiUpdates
                                                                                                  ? dateFilter(
                                                                                                      kpi.lastUpdateTs,
                                                                                                    )
                                                                                                  : '---'}
                                                                                              </div>
                                                                                              <div className="kpi-status-table-cell">
                                                                                                <KpiDropdownMenu
                                                                                                  onClickEdit={() => {
                                                                                                    setSelectedKpi(
                                                                                                      kpi,
                                                                                                    )
                                                                                                    setAddEditModalMode(
                                                                                                      'edit',
                                                                                                    )
                                                                                                    setIsOpenAddEditKPIModal(
                                                                                                      true,
                                                                                                    )
                                                                                                    setSelectedObjId(
                                                                                                      l3KpiReport.objId,
                                                                                                    )
                                                                                                  }}
                                                                                                  // onClickUpdateComplete={() => {
                                                                                                  //   updateComplete(kpi)
                                                                                                  // }}
                                                                                                  fromKpiReport={
                                                                                                    true
                                                                                                  }
                                                                                                  backgroundColor={
                                                                                                    l3Index %
                                                                                                      2 ===
                                                                                                    0
                                                                                                      ? '#f5f5f5'
                                                                                                      : 'white'
                                                                                                  }
                                                                                                  isCompleted={
                                                                                                    kpi.isCompleted
                                                                                                  }
                                                                                                  onClickSeeUpdates={() => {
                                                                                                    setSelectedKpiUpdate(
                                                                                                      kpi,
                                                                                                    )
                                                                                                    setIsOpenKPIUpdatesModal(
                                                                                                      true,
                                                                                                    )
                                                                                                    setSelectedObjId(
                                                                                                      l3KpiReport.objId,
                                                                                                    )
                                                                                                  }}
                                                                                                  onClickAddUpdates={() => {
                                                                                                    setSelectedKpiUpdate(
                                                                                                      kpi,
                                                                                                    )
                                                                                                    setIsOpenUpdateModal(
                                                                                                      true,
                                                                                                    )
                                                                                                    setSelectedObjId(
                                                                                                      l3KpiReport.objId,
                                                                                                    )
                                                                                                  }}
                                                                                                />
                                                                                              </div>
                                                                                            </>
                                                                                          </React.Fragment>
                                                                                        </>
                                                                                      )
                                                                                  },
                                                                                )}
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      ) : (
                                                                        <div
                                                                          style={{
                                                                            marginLeft: '1.9rem',
                                                                          }}
                                                                        >
                                                                          {t('kpi.noKPIsMessage')}
                                                                        </div>
                                                                      )}
                                                                    </>
                                                                  )}
                                                              </div>
                                                            </>
                                                          )
                                                      })}
                                                  </div>
                                                </React.Fragment>
                                              </div>
                                            </>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                )
                            })}
                          {requestedLayer === 2 &&
                            l1KpiReport.bauKpiReportsRaw.map((bauKpiReport, bauIndex) => {
                              if (
                                filterByTagsLevel2(bauKpiReport) === false ||
                                filterByKPIOwnerLevel2(bauKpiReport) === false
                              ) {
                                return null
                              }
                              if (
                                selectedDaysForShowingWorseKpis !== null &&
                                filterByWorseKpisExist(
                                  l1KpiReport?.bauKpiReportsStats?.sumKpiReportStats,
                                ) === false
                              ) {
                                return null
                              }
                              if (
                                filterByColor(
                                  l1KpiReport?.bauKpiReportsStats?.sumKpiReportStats,
                                ) === false
                              )
                                return null
                              else
                                return (
                                  <div
                                    className="shadow-kpi-report"
                                    style={{
                                      width: '100%',
                                      marginBottom: '1rem',
                                      padding: '0.8rem',
                                    }}
                                    key={bauIndex}
                                  >
                                    <div>
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          columnGap: '1rem',
                                          justifyContent: 'space-between',
                                          paddingRight: '0rem',
                                          paddingTop: '0.3rem',
                                          paddingBottom: '0.3rem',
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '100%',
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'flex-start',
                                            }}
                                          >
                                            {(isL2Hidden[bauKpiReport.l2ObjId] === undefined ||
                                              isL2Hidden[bauKpiReport.l2ObjId]) && (
                                              <span
                                                class="material-symbols-outlined"
                                                onClick={() => {
                                                  toggleParentContent(bauKpiReport.l2ObjId)
                                                }}
                                                style={{ fontSize: '30px', cursor: 'pointer' }}
                                              >
                                                arrow_drop_down
                                              </span>
                                            )}

                                            {isL2Hidden[bauKpiReport.l2ObjId] !== undefined &&
                                              !isL2Hidden[bauKpiReport.l2ObjId] && (
                                                <span
                                                  class="material-symbols-outlined"
                                                  onClick={() => {
                                                    toggleParentContent(bauKpiReport.l2ObjId)
                                                  }}
                                                  style={{ fontSize: '30px', cursor: 'pointer' }}
                                                >
                                                  arrow_drop_up
                                                </span>
                                              )}
                                          </div>
                                          <div
                                            style={{
                                              display: 'flex',
                                              width: hideTitle ? '67.8%' : '65.3%',
                                            }}
                                          >
                                            <span
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '90%',
                                              }}
                                            >
                                              <ObjectiveStatement>
                                                {t('kpi.BAU')}
                                              </ObjectiveStatement>
                                            </span>
                                          </div>
                                          <RagList
                                            reportStats={bauKpiReportsStats?.[l1KpiReport.l1ObjId]}
                                          />
                                        </div>

                                        <div
                                          style={{
                                            display: 'flex',
                                            width: '10%',
                                            justifyContent: 'flex-end',
                                            alignItems: 'flex-start',
                                            //columnWidth: '0.3rem',
                                            columnGap: '0.3rem',
                                          }}
                                        >
                                          <div
                                            style={{
                                              //marginTop: '0.3rem',
                                              display: 'flex',
                                              flexDirection: 'row',
                                              columnGap: '1rem',
                                              justifyContent: 'space-between',
                                              //wordBreak: 'break-all',
                                              //width:"3rem"
                                            }}
                                          ></div>
                                        </div>

                                        {isL2Hidden[bauKpiReport.l2ObjId] !== undefined &&
                                          !isL2Hidden[bauKpiReport.l2ObjId] && (
                                            <>
                                              <div
                                                className="milestones-container"
                                                style={{ fontSize: '14px' }}
                                              >
                                                <React.Fragment key={`obj-${bauIndex}`}>
                                                  <div>
                                                    <div
                                                      className="divider"
                                                      style={{ marginBottom: '0.4rem' }}
                                                    ></div>
                                                    {bauKpiReport.l3KpiList
                                                      .sort((a, b) =>
                                                        a.objDescription.localeCompare(
                                                          b.objDescription,
                                                        ),
                                                      )
                                                      .map((l3KpiReport, l3Index) => {
                                                        let l3Stats = bauKpiReportsStats?.[
                                                          l1KpiReport.l1ObjId
                                                        ]?.childrenKpiReportStats.find(
                                                          (l3KpiReportStats) =>
                                                            l3KpiReportStats.objId ===
                                                            l3KpiReport.objId,
                                                        )
                                                        if (
                                                          filterByTagsLevel3(l3KpiReport) ===
                                                            false ||
                                                          filterByKPIOwnerLevel3(l3KpiReport) ===
                                                            false
                                                        ) {
                                                          return null
                                                        }
                                                        if (
                                                          selectedDaysForShowingWorseKpis !==
                                                            null &&
                                                          filterByWorseKpisExist(
                                                            l3Stats?.sumKpiReportStats,
                                                          ) === false
                                                        ) {
                                                          return null
                                                        }
                                                        if (
                                                          filterByColor(
                                                            l3Stats?.sumKpiReportStats,
                                                          ) === false
                                                        )
                                                          return null
                                                        else
                                                          return (
                                                            <>
                                                              <div
                                                                style={{
                                                                  display: 'flex',
                                                                  flexDirection: 'column',
                                                                  columnGap: '1rem',
                                                                  justifyContent: 'space-between',
                                                                  paddingTop: '0.3rem',
                                                                  paddingBottom: '0.3rem',
                                                                  paddingLeft: '0.3rem',
                                                                  paddingRight: '0rem',
                                                                  // marginLeft:'0.5rem',
                                                                  backgroundColor:
                                                                    l3Index % 2 === 0
                                                                      ? '#f5f5f5'
                                                                      : 'white',
                                                                }}
                                                                key={l3KpiReport.objId}
                                                              >
                                                                <div
                                                                  style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    width: '100%',
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      display: 'flex',
                                                                      justifyContent: 'flex-start',
                                                                    }}
                                                                  >
                                                                    {(isL3Hidden[
                                                                      l3KpiReport.objId
                                                                    ] === undefined ||
                                                                      isL3Hidden[
                                                                        l3KpiReport.objId
                                                                      ]) && (
                                                                      <span
                                                                        class="material-symbols-outlined"
                                                                        onClick={() => {
                                                                          toggleChildContent(
                                                                            l3KpiReport.objId,
                                                                          )
                                                                        }}
                                                                        style={{
                                                                          fontSize: '30px',
                                                                          cursor: 'pointer',
                                                                        }}
                                                                      >
                                                                        arrow_drop_down
                                                                      </span>
                                                                    )}

                                                                    {isL3Hidden[
                                                                      l3KpiReport.objId
                                                                    ] !== undefined &&
                                                                      !isL3Hidden[
                                                                        l3KpiReport.objId
                                                                      ] && (
                                                                        <span
                                                                          class="material-symbols-outlined"
                                                                          onClick={() => {
                                                                            toggleChildContent(
                                                                              l3KpiReport.objId,
                                                                            )
                                                                          }}
                                                                          style={{
                                                                            fontSize: '30px',
                                                                            cursor: 'pointer',
                                                                          }}
                                                                        >
                                                                          arrow_drop_up
                                                                        </span>
                                                                      )}
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      display: 'flex',
                                                                      width: '65%',
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        width: '90%',
                                                                      }}
                                                                    >
                                                                      <ObjectiveStatement>
                                                                        {l3KpiReport.objDescription}
                                                                      </ObjectiveStatement>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      marginLeft: 'auto',
                                                                      display: 'flex',
                                                                      flexDirection: 'row',
                                                                    }}
                                                                  >
                                                                    <span
                                                                      class=" tooltip"
                                                                      style={{
                                                                        marginLeft: '0.5rem',
                                                                        marginRight: '0.2rem',
                                                                      }}
                                                                    >
                                                                      <NameBox>
                                                                        {l3KpiReport.objOwnerName.match(
                                                                          /\b(\w)/g,
                                                                        )}
                                                                      </NameBox>
                                                                      <span class="tooltiptext">
                                                                        {
                                                                          l3KpiReport.objOwnerName
                                                                        }{' '}
                                                                      </span>
                                                                    </span>
                                                                    <RagList
                                                                      reportStats={l3Stats}
                                                                    />
                                                                  </div>
                                                                </div>
                                                                {isL3Hidden[l3KpiReport.objId] !==
                                                                  undefined &&
                                                                  !isL3Hidden[
                                                                    l3KpiReport.objId
                                                                  ] && (
                                                                    <>
                                                                      <div
                                                                        className="divider"
                                                                        style={{
                                                                          marginBottom: '0.4rem',
                                                                        }}
                                                                      ></div>
                                                                      {l3KpiReport.kpis?.length >
                                                                      0 ? (
                                                                        <div
                                                                          style={{
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            gap: '0.5rem',
                                                                            marginLeft: '0.8rem',
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              marginBottom:
                                                                                '0.2rem',
                                                                              paddingTop: '1rem',
                                                                            }}
                                                                          >
                                                                            <div
                                                                              className={
                                                                                'kpi-status-table-l3-new'
                                                                              }
                                                                            >
                                                                              {/* <div className="chart-log-meeting-header ">
                                                                                    </div> */}
                                                                              <div className="chart-log-meeting-header-kpi "></div>

                                                                              <div className="chart-log-meeting-header-kpi ">
                                                                                {t('kpi.name')}
                                                                              </div>
                                                                              <div className="chart-log-meeting-header-kpi">
                                                                                {t('kpi.target')}
                                                                              </div>
                                                                              {/* <div className="chart-log-meeting-header-kpi">
                                                                                {t('kpi.reachBy')}
                                                                              </div> */}
                                                                              <div className="chart-log-meeting-header-kpi">
                                                                                {t(
                                                                                  'kpi.latestValue',
                                                                                )}
                                                                              </div>
                                                                              <div className="chart-log-meeting-header-kpi">
                                                                                {t('kpi.madeBy')}
                                                                              </div>
                                                                              <div className="chart-log-meeting-header-kpi">
                                                                                {t('kpi.notes')}
                                                                              </div>
                                                                              <div className="chart-log-meeting-header-kpi">
                                                                                {t('kpi.ownerName')}
                                                                              </div>
                                                                              <div className="chart-log-meeting-header-kpi">
                                                                                {t('kpi.status')}
                                                                              </div>
                                                                              <div className="chart-log-meeting-header-kpi">
                                                                                {t('kpi.topic')}
                                                                              </div>

                                                                              <div className="chart-log-meeting-header-kpi">
                                                                                {t('kpi.frequency')}
                                                                              </div>

                                                                              <div className="chart-log-meeting-header-kpi">
                                                                                {t('kpi.updatedOn')}
                                                                                <br />
                                                                                <span
                                                                                  style={{
                                                                                    fontSize:
                                                                                      '0.8rem',
                                                                                  }}
                                                                                >
                                                                                  (MM/DD/YY)
                                                                                </span>
                                                                              </div>
                                                                              <div className="chart-log-meeting-header-kpi"></div>

                                                                              {l3KpiReport.kpis
                                                                                ?.filter((kpi) => {
                                                                                  if (
                                                                                    shouldShowCompleted
                                                                                  ) {
                                                                                    return true
                                                                                  }
                                                                                  return !kpi.isCompleted
                                                                                })
                                                                                ?.sort((a, b) => {
                                                                                  if (
                                                                                    a.isCompleted &&
                                                                                    !b.isCompleted
                                                                                  ) {
                                                                                    return 1
                                                                                  }
                                                                                  if (
                                                                                    !a.isCompleted &&
                                                                                    b.isCompleted
                                                                                  ) {
                                                                                    return -1
                                                                                  }
                                                                                  return 0
                                                                                })
                                                                                ?.map(
                                                                                  (
                                                                                    kpi,
                                                                                    kpiIndex,
                                                                                  ) => {
                                                                                    if (
                                                                                      filterByColorKpi(
                                                                                        kpi,
                                                                                      ) === false
                                                                                    )
                                                                                      return null
                                                                                    else
                                                                                      return (
                                                                                        <>
                                                                                          <React.Fragment
                                                                                            key={`objective-${kpi.kpiId}`}
                                                                                          >
                                                                                            {/* <div className="chart-log-meeting-description"></div> */}
                                                                                            <>
                                                                                              <div
                                                                                                className="kpi-status-table-cell"
                                                                                                style={{
                                                                                                  textAlign:
                                                                                                    'left',
                                                                                                  display:
                                                                                                    'flex',
                                                                                                  flexDirection:
                                                                                                    'row',
                                                                                                }}
                                                                                              >
                                                                                                <span class="material-symbols-outlined icon-size">
                                                                                                  {kpi.kpiType ===
                                                                                                  3
                                                                                                    ? 'distance'
                                                                                                    : 'tag'}
                                                                                                </span>
                                                                                                {kpi.isCompleted ? (
                                                                                                  <span
                                                                                                    className="material-symbols-outlined"
                                                                                                    style={{
                                                                                                      color:
                                                                                                        'green',
                                                                                                    }}
                                                                                                  >
                                                                                                    check
                                                                                                  </span>
                                                                                                ) : null}
                                                                                              </div>
                                                                                              <div
                                                                                                className="kpi-status-table-cell"
                                                                                                style={{
                                                                                                  textAlign:
                                                                                                    'left',
                                                                                                  display:
                                                                                                    'flex',
                                                                                                  flexDirection:
                                                                                                    'column',
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  {
                                                                                                    kpi.name
                                                                                                  }
                                                                                                </span>
                                                                                              </div>
                                                                                              <div
                                                                                                className="kpi-status-table-cell"
                                                                                                style={{
                                                                                                  textAlign:
                                                                                                    'left',
                                                                                                }}
                                                                                              >
                                                                                                {kpi.unit && (
                                                                                                  <>
                                                                                                    {kpi.kpiType ===
                                                                                                    3
                                                                                                      ? ''
                                                                                                      : kpi.target.toLocaleString()}{' '}
                                                                                                    {
                                                                                                      kpi.unit
                                                                                                    }{' '}
                                                                                                  </>
                                                                                                )}
                                                                                                {t(
                                                                                                  'Common:kpi.by',
                                                                                                )}
                                                                                                <br />
                                                                                                <span
                                                                                                  style={{
                                                                                                    wordBreak:
                                                                                                      'keep-all',
                                                                                                  }}
                                                                                                >
                                                                                                  {
                                                                                                    kpi.targetDate
                                                                                                  }
                                                                                                </span>
                                                                                              </div>
                                                                                              {/* <div
                                                                                                className="kpi-status-table-cell"
                                                                                                style={{
                                                                                                  textAlign:
                                                                                                    'left',
                                                                                                }}
                                                                                              >
                                                                                                {kpi.targetDate.slice(
                                                                                                  0,
                                                                                                  kpi.targetDate.lastIndexOf(
                                                                                                    '/',
                                                                                                  ),
                                                                                                )}
                                                                                              </div> */}
                                                                                              <div
                                                                                                className="kpi-status-table-cell"
                                                                                                style={{
                                                                                                  textAlign:
                                                                                                    'left',
                                                                                                }}
                                                                                              >
                                                                                                {kpi.kpiType ===
                                                                                                3
                                                                                                  ? convertEnumToTextUpdateValue(
                                                                                                      kpi.lastUpdateAmount,
                                                                                                    )
                                                                                                  : kpi.hasKpiUpdates
                                                                                                    ? kpi.lastUpdateAmount.toLocaleString()
                                                                                                    : '---'}
                                                                                              </div>
                                                                                              <div
                                                                                                className="kpi-status-table-cell"
                                                                                                style={{
                                                                                                  textAlign:
                                                                                                    'left',
                                                                                                }}
                                                                                              >
                                                                                                {kpi.lastUpdateCreatedBy ===
                                                                                                ''
                                                                                                  ? '---'
                                                                                                  : truncateName(
                                                                                                      shortenName(
                                                                                                        kpi.lastUpdateCreatedBy,
                                                                                                      ),
                                                                                                    )}
                                                                                              </div>
                                                                                              <div
                                                                                                className="kpi-status-table-cell"
                                                                                                style={{
                                                                                                  textAlign:
                                                                                                    'left',
                                                                                                }}
                                                                                              >
                                                                                                {kpi?.lastUpdateComment !==
                                                                                                ''
                                                                                                  ? kpi?.lastUpdateComment
                                                                                                  : '---'}
                                                                                              </div>
                                                                                              <div
                                                                                                className="kpi-status-table-cell"
                                                                                                style={{
                                                                                                  textAlign:
                                                                                                    'left',
                                                                                                  display:
                                                                                                    'flex',
                                                                                                  flexDirection:
                                                                                                    'row',
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  {truncateName(
                                                                                                    shortenName(
                                                                                                      kpi.ownerName,
                                                                                                    ),
                                                                                                  )}
                                                                                                </span>
                                                                                              </div>
                                                                                              <div className="kpi-status-table-cell">
                                                                                                <div
                                                                                                  style={{
                                                                                                    backgroundColor:
                                                                                                      kpi.ragStatus ===
                                                                                                        -1 ||
                                                                                                      kpi.ragStatusWhenOverdue ===
                                                                                                        -1
                                                                                                        ? 'lightgray'
                                                                                                        : kpi.ragStatus ===
                                                                                                            0
                                                                                                          ? 'green'
                                                                                                          : kpi.ragStatus ===
                                                                                                              1
                                                                                                            ? 'gold'
                                                                                                            : 'red',
                                                                                                    width:
                                                                                                      '2rem',
                                                                                                    height:
                                                                                                      '2rem',
                                                                                                    // marginLeft:"1rem"
                                                                                                  }}
                                                                                                ></div>
                                                                                              </div>
                                                                                              <div
                                                                                                className="kpi-status-table-cell"
                                                                                                style={{
                                                                                                  display:
                                                                                                    'flex',
                                                                                                  flexDirection:
                                                                                                    'row',
                                                                                                  gap: '1rem',
                                                                                                  textAlign:
                                                                                                    'left',
                                                                                                }}
                                                                                              >
                                                                                                <div
                                                                                                  style={{
                                                                                                    textAlign:
                                                                                                      'left',
                                                                                                  }}
                                                                                                >
                                                                                                  {(kpi.ragStatus ===
                                                                                                    0 ||
                                                                                                    kpi.ragStatus ===
                                                                                                      -1) && (
                                                                                                    <span>
                                                                                                      {
                                                                                                        '---'
                                                                                                      }
                                                                                                    </span>
                                                                                                  )}
                                                                                                  {kpi.lastUpdateUmtId >
                                                                                                    0 &&
                                                                                                    (kpi.ragStatus ===
                                                                                                      1 ||
                                                                                                      kpi.ragStatus ===
                                                                                                        2) && (
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            'blue',
                                                                                                          textDecoration:
                                                                                                            'underline',
                                                                                                          cursor:
                                                                                                            'pointer',
                                                                                                        }}
                                                                                                        onClick={() => {
                                                                                                          setCardModalUmtId(
                                                                                                            kpi.lastUpdateUmtId,
                                                                                                          )

                                                                                                          if (
                                                                                                            kpi.ragStatus ===
                                                                                                            1
                                                                                                          ) {
                                                                                                            setCardModalTopicType(
                                                                                                              2,
                                                                                                            )
                                                                                                          } else if (
                                                                                                            kpi.ragStatus ===
                                                                                                            2
                                                                                                          ) {
                                                                                                            setCardModalTopicType(
                                                                                                              1,
                                                                                                            )
                                                                                                          }

                                                                                                          setIsOpenCardModal(
                                                                                                            true,
                                                                                                          )
                                                                                                        }}
                                                                                                      >
                                                                                                        {kpi.ragStatus ===
                                                                                                        1
                                                                                                          ? t(
                                                                                                              'kpi.seeGuidance',
                                                                                                            )
                                                                                                          : kpi.ragStatus ===
                                                                                                              2
                                                                                                            ? t(
                                                                                                                'kpi.seeBlocker',
                                                                                                              )
                                                                                                            : '---'}
                                                                                                      </span>
                                                                                                    )}
                                                                                                  {/* {latestKpiUpdate === undefined && (<span>{'---'}</span>)} */}
                                                                                                </div>
                                                                                              </div>

                                                                                              <div
                                                                                                className="kpi-status-table-cell"
                                                                                                style={{
                                                                                                  display:
                                                                                                    'flex',
                                                                                                  flexDirection:
                                                                                                    'row',
                                                                                                  gap: '1rem',
                                                                                                  textAlign:
                                                                                                    'left',
                                                                                                }}
                                                                                              >
                                                                                                <div
                                                                                                  style={{
                                                                                                    textAlign:
                                                                                                      'left',
                                                                                                  }}
                                                                                                >
                                                                                                  {convertFrequencyEnumToText(
                                                                                                    kpi.frequency,
                                                                                                  )}
                                                                                                </div>

                                                                                                {/* {latestKpiUpdate === undefined && (<span>{'---'}</span>)} */}
                                                                                              </div>

                                                                                              <div
                                                                                                className="kpi-status-table-cell"
                                                                                                style={{
                                                                                                  textAlign:
                                                                                                    'left',
                                                                                                }}
                                                                                              >
                                                                                                {kpi.hasKpiUpdates
                                                                                                  ? dateFilter(
                                                                                                      kpi.lastUpdateTs,
                                                                                                    )
                                                                                                  : '---'}
                                                                                              </div>
                                                                                              <div className="kpi-status-table-cell">
                                                                                                <KpiDropdownMenu
                                                                                                  onClickEdit={() => {
                                                                                                    setSelectedKpi(
                                                                                                      kpi,
                                                                                                    )
                                                                                                    setAddEditModalMode(
                                                                                                      'edit',
                                                                                                    )
                                                                                                    setIsOpenAddEditKPIModal(
                                                                                                      true,
                                                                                                    )
                                                                                                    setSelectedObjId(
                                                                                                      l3KpiReport.objId,
                                                                                                    )
                                                                                                  }}
                                                                                                  // onClickUpdateComplete={() => {
                                                                                                  //   updateComplete(kpi)
                                                                                                  // }}
                                                                                                  fromKpiReport={
                                                                                                    true
                                                                                                  }
                                                                                                  backgroundColor={
                                                                                                    l3Index %
                                                                                                      2 ===
                                                                                                    0
                                                                                                      ? '#f5f5f5'
                                                                                                      : 'white'
                                                                                                  }
                                                                                                  isCompleted={
                                                                                                    kpi.isCompleted
                                                                                                  }
                                                                                                  onClickSeeUpdates={() => {
                                                                                                    setSelectedKpiUpdate(
                                                                                                      kpi,
                                                                                                    )
                                                                                                    setIsOpenKPIUpdatesModal(
                                                                                                      true,
                                                                                                    )
                                                                                                    setSelectedObjId(
                                                                                                      l3KpiReport.objId,
                                                                                                    )
                                                                                                  }}
                                                                                                  onClickAddUpdates={() => {
                                                                                                    setSelectedKpiUpdate(
                                                                                                      kpi,
                                                                                                    )
                                                                                                    setIsOpenUpdateModal(
                                                                                                      true,
                                                                                                    )
                                                                                                    setSelectedObjId(
                                                                                                      l3KpiReport.objId,
                                                                                                    )
                                                                                                  }}
                                                                                                />
                                                                                              </div>
                                                                                            </>
                                                                                          </React.Fragment>
                                                                                        </>
                                                                                      )
                                                                                  },
                                                                                )}
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      ) : (
                                                                        <div
                                                                          style={{
                                                                            marginLeft: '1.9rem',
                                                                          }}
                                                                        >
                                                                          {t('kpi.noKPIsMessage')}
                                                                        </div>
                                                                      )}
                                                                    </>
                                                                  )}
                                                              </div>
                                                            </>
                                                          )
                                                      })}
                                                  </div>
                                                </React.Fragment>
                                              </div>
                                            </>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                )
                            })}
                        </div>
                      )
                  })}
            </>
          )}
        </div>
      </div>
    </>
  )
}

const KpiReportByLeaderWithContext = () => {
  return (
    <ApplicationLayout>
      {hasRole('team') && getConsolidatedConfigSettings('enable_l2_kpi') === true ? (
        <></>
      ) : (
        <KpiReportByLeader showLeaderDropdown={true} />
      )}
    </ApplicationLayout>
  )
}

export { KpiReportByLeader, KpiReportByLeaderWithContext }
